import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContainer, AuthHeader, LoaderButton } from "../../../components";
import { loginAction } from "../../../redux/actions";
import { LoginVS, loginFormFields, networkText } from "../../../utilities";

const Login = () => {
  const dispatch = useDispatch(null);
  const navigate = useNavigate();
  const { loginLoad } = useSelector((state) => state?.auth);

  const onLogin = async (values) => {
    if (navigator.onLine) {
      const requestBody = {
        username: values.username.trim(),
        password: values.password.trim(),
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
            if (res?.user?.role == "Installer") {
              navigate("/induction");
            } else {
              navigate("/");
            }
          }
        },
        onFailure: (error) => {},
      };

      dispatch(loginAction(body));
    } else {
      toast.error(networkText);
    }
  };

  return (
    <AppContainer loading={false} style={{ position: "relative" }}>
      <div>
        <AuthHeader />
        <div className="Auth-form-container background-image-con">
          <div className="Background-overlay">
            <div>
              <Formik
                initialValues={loginFormFields}
                validationSchema={LoginVS}
                onSubmit={(values) => {
                  onLogin(values);
                }}
              >
                {({ errors, status, touched, handleSubmit }) => (
                  <Form className="Auth-form" onSubmit={handleSubmit}>
                    <div className="Auth-form-content">
                      <h3 className="Auth-form-title">Sign In</h3>
                      <div className="form-group mt-3">
                        <label className="labelText">Username</label>
                        <Field
                          type="text"
                          className="form-control mt-1"
                          placeholder="Enter username"
                          name="username"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label className="labelText">Password</label>
                        <Field
                          type="password"
                          className="form-control mt-1"
                          placeholder="Enter password"
                          name="password"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="linkText">
                        <Link style={{ color: "#252525" }} to="/ForgotPassword">
                          Forgot Password
                        </Link>
                      </div>

                      <LoaderButton
                        title={"Submit"}
                        onClick={handleSubmit}
                        isLoading={loginLoad}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default Login;
