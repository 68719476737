import { Col, Row } from "antd";
import { Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import {
  CommonCard,
  DateIntervalInput,
  FilterDropDown,
  LoaderButton,
  DateInput,
} from "../../components";
import {
  customReportImage,
  preReportFormFields,
  preReportVS,
  switchOptions,
} from "../../utilities";
import Item from "antd/es/list/Item";

export const PreReportCard = ({
  mT,
  sites,
  onSubmit,
  formats,
  setFormat,
  dataType,
  setDataType,
  dataTypes,
  getValue,
}) => {
  const initialSelectedIndex = switchOptions.findIndex(
    ({ value }) => value === "daily"
  );
  const { reportLoad } = useSelector((state) => state?.report);
  return (
    <Formik
      initialValues={preReportFormFields}
      validationSchema={preReportVS}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
      }}
    >
      {({ errors, status, touched, handleSubmit, setFieldValue, values }) => {
        return (
          <Form className="Auth-form" onSubmit={handleSubmit}>
            <CommonCard mT={mT} title={"Generate Report (Predefined-Template)"}>
              <Row className="preRepbottomCon" gutter={[0, 50]}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <FilterDropDown
                    label={"Export Type"}
                    value={values?.reportFormat || null}
                    placeholder={"Select Format"}
                    vldName={"reportFormat"}
                    list={formats.map((data) => data?.value)}
                    onSelect={(item) => {
                      setFieldValue("reportFormat", item);
                      setFormat(item);
                    }}
                  />

                  {values?.reportFormat == "pdf" ? (
                    <>
                      <FilterDropDown
                        label={"Site"}
                        value={values?.site}
                        placeholder={"Select Site"}
                        vldName={"site"}
                        list={sites.map((data) => data?.label)}
                        onSelect={(item) => {
                          setFieldValue("site", item);
                        }}
                        mode={"single"}
                      />

                      <FilterDropDown
                        label={"Data Type"}
                        value={values?.dataType || null}
                        placeholder={"Select data type"}
                        vldName={"dataType"}
                        list={dataTypes.map((data) => data?.label)}
                        onSelect={(item) => {
                          setFieldValue("dataType", item);
                        }}
                      />

                      <DateIntervalInput
                        label={"Time Period"}
                        // value={values?.timePeriod}
                        vldName={"timePeriod"}
                        placeholder={"Enter Time Period"}
                        onSelect={(item) => {
                          setFieldValue("timePeriod", item);
                        }}
                        formatType={values?.dataType}
                      />
                    </>
                  ) : (
                    <>
                      <FilterDropDown
                        label={"Sites"}
                        value={values?.sites || null}
                        placeholder={"Select Sites"}
                        vldName={"sites"}
                        list={sites.map((data) => data?.label)}
                        onSelect={(item) => {
                          setFieldValue("sites", item);
                        }}
                        mode={"multiple"}
                      />
                    </>
                  )}

                  <div className="Auth-form-content">
                    <LoaderButton
                      title={"Generate"}
                      onClick={handleSubmit}
                      isLoading={reportLoad}
                    />
                  </div>
                  {/* <CommonCard
                    buttonTitle={"Generate"}
                    onClick={handleSubmit}
                  ></CommonCard> */}
                </Col>

                <Col className="imgCon" xs={24} sm={24} md={16} lg={16} xl={16}>
                  <img
                    className="preReportImg"
                    height={"90%"}
                    width={"50%"}
                    src={customReportImage}
                  />
                </Col>
              </Row>
            </CommonCard>
          </Form>
        );
      }}
    </Formik>
  );
};
