import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AppHeading, VendorTable } from "../../components";
import {
  getStationFilterVendorAction,
  getAllSitesAction,
  getStationVendorAction,
} from "../../redux/actions";
import { networkText } from "../../utilities";
import Box from "@mui/material/Box";
import { VendorView } from "./VendorView";

const Vendor = ({ navigation }) => {
  const dispatch = useDispatch(null);
  const navigate = useNavigate();

  const { sites } = useSelector((state) => state?.sites);
  const [viewOption, setViewOption] = useState("daily");
  const [selectedDate, setSelectedDate] = useState(null);
  const [vendorData, setVendorData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null); // State to hold the object of selected site and date
  const [dataStatus, setDataStatus] = useState(false);

  useEffect(() => {
    getAllSites();
  }, []);

  const scrollToDown = () => {
    window.scrollTo({
      top: 1500,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (vendorData && dataStatus) {
      scrollToDown();
    }
  }, [vendorData]);

  const getAllSites = () => {
    if (navigator.onLine) {
      const requestBody = {
        values: null,
        onSuccess: (res) => {},
        onFailure: () => {},
      };
      dispatch(getAllSitesAction(requestBody));
    } else {
      toast.error(networkText);
    }
  };

  const onFindClick = async (item) => {
    setDataStatus(true);
    if (navigator.onLine) {
      const requestBody = {
        station_name: item.siteName,
        station_date: item.vendorDate,
      };
      const body = {
        values: requestBody,

        onSuccess: async (res) => {
          setVendorData(res?.data); // Set the fetched data
          if (
            !res?.data?.production &&
            !res?.data?.income &&
            !res?.data?.grid_power &&
            !res?.data?.consumption
          ) {
            toast.error("No Data Found.");
            setDataStatus(false);
          }
        },
        onFailure: (res) => {},
      };
      dispatch(getStationVendorAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const onSubmit = async (item) => {
    if (navigator.onLine) {
      const requestBody = {
        station_name: item.siteName,
        station_date: item.vendorDate,
      };
      const body = {
        values: requestBody,

        onSuccess: async (res) => {
          toast.success(res?.message);
        },
        onFailure: (res) => {},
      };
      dispatch(getStationFilterVendorAction(body));
    } else {
      toast.error(networkText);
    }
  };

  return (
    <Col className="reportCon">
      <Box sx={{ p: 3, width: "100%" }}>
        <AppHeading title={"Vendor"} />
        <VendorView
          selectedDate={selectedDate}
          setSelectedDate={(item) => setSelectedDate(item)}
          setViewOption={(item) => setViewOption(item)}
          viewOption={viewOption}
          sites={
            sites &&
            sites.map((entry) => ({
              label: entry?.station_name,
              value: entry?.station_code,
            }))
          }
          onSubmit={(item) => {
            setSelectedItem(item);
            onFindClick(item);
          }}
        />
        {dataStatus && (
          <VendorTable
            data={vendorData}
            onSubmit={() => {
              onSubmit(selectedItem);
            }}
          />
        )}
      </Box>
    </Col>
  );
};

export default Vendor;
