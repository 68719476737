import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AppContainer,
  AppHeading,
  ChartCard,
  ChartCard1,
  FilterDropDown,
  LineCharts,
  BarCharts,
  SiteDetCard1,
  SiteDetCard2,
  WeatherComponent,
  AppButton,
} from "../../../components";
import {
  editFactorAction,
  getFactorAction,
  getSiteDetailAction,
  getWeatherAction,
  getActualVsExpectedAction,
  getPowerMixAction,
  getInverterWisePowerAction,
} from "../../../redux/actions";

import { formatDate, networkText } from "../../../utilities";
import { filterOptions } from "../../../utilities";
import Box from "@mui/material/Box";
const SiteDetails = ({ navigation }) => {
  const dispatch = useDispatch(null);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.data?.id || null;
  const lat = location?.state?.data?.latitude || null;
  const long = location?.state?.data?.longitude || null;
  const siteName = location?.state?.data?.station_name || "Site Details";
  const station_code = location?.state?.data?.station_code;
  const {
    siteDetail,
    siteDetload,
    weatherRecord,
    sitesDetFetchTime,
    actualVsExpected,
    powerMix,
    inverterWisePower,
    inverterList,
  } = useSelector((state) => state?.sites);

  const { userInfo } = useSelector((state) => state?.auth);

  const [isOpen, setIsOpen] = useState(false);
  const [isCo2Open, setIsCo2Open] = useState(false);
  const [isTreeOpen, setIsTreeOpen] = useState(false);
  const [dataType1, setDataType1] = useState("day");
  const [dataType2, setDataType2] = useState("day");
  const [dataType3, setDataType3] = useState("day");
  const { factor, factorLoad, co2Factor } = useSelector(
    (state) => state?.factor
  );
  const [factorValue, setFactorValue] = useState(co2Factor);

  useEffect(() => {
    getData(id);
    getWeather(lat, long);
    getFactor();
  }, []);

  const handleRefreshClick = () => {
    window.location.reload();
  };

  useEffect(() => {
    getActualVsExpectedData(station_code);
  }, [dataType1]);
  useEffect(() => {
    getPowerMixData(station_code);
  }, [dataType2]);
  useEffect(() => {
    getInverterWisePowerData(station_code);
  }, [dataType3]);

  const getData = async (value) => {
    if (navigator.onLine) {
      const requestBody = {
        station_id: value,
        data_type: dataType1,
      };

      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
          }
        },
        onFailure: (error) => {},
      };

      dispatch(getSiteDetailAction(body));
    } else {
      toast.error(networkText);
    }
  };
  const getActualVsExpectedData = async (value) => {
    if (navigator.onLine) {
      const requestBody = {
        station_code: value,
        data_type: dataType1,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
          }
        },
        onFailure: (error) => {},
      };

      dispatch(getActualVsExpectedAction(body));
    } else {
      toast.error(networkText);
    }
  };
  const getPowerMixData = async (value) => {
    if (navigator.onLine) {
      const requestBody = {
        station_code: value,
        data_type: dataType2,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
          }
        },
        onFailure: (error) => {},
      };

      dispatch(getPowerMixAction(body));
    } else {
      toast.error(networkText);
    }
  };
  const getInverterWisePowerData = async (value) => {
    if (navigator.onLine) {
      const requestBody = {
        station_code: value,
        data_type: dataType3,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
          }
        },
        onFailure: (error) => {},
      };

      dispatch(getInverterWisePowerAction(body));
    } else {
      toast.error(networkText);
    }
  };
  const getWeather = async (lat, long) => {
    if (navigator.onLine) {
      const requestBody = {
        lat: lat || 31.582045,
        long: long || 74.329376,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
          }
        },
        onFailure: (error) => {},
      };

      dispatch(getWeatherAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const getFactor = () => {
    if (navigator.onLine) {
      const requestBody = {
        values: null,
        onSuccess: (res) => {},
        onFailure: () => {},
      };
      dispatch(getFactorAction(requestBody));
    } else {
      toast.error(networkText);
    }
  };
  ////////////////////////////
  const renderChart1 = (data) => {
    return (
      <ChartCard paddingLeft={20} bgColor={"#F0F0F0"}>
        {dataType1 === "day" ? (
          <LineCharts
            data={data}
            dataKey={["expected", "actual"]}
            nameKey={"time"}
            showGridVertical={true}
            showGridHorizontal={true}
          />
        ) : (
          <BarCharts
            data={data}
            dataKeys={["actual"]}
            nameKey={"time"}
            showGridVertical={true}
            showGridHorizontal={true}
          />
        )}
      </ChartCard>
    );
  };
  const renderChart2 = (data) => {
    return (
      <ChartCard paddingLeft={20} paddingRight={20} bgColor={"#F0F0F0"}>
        {dataType2 === "day" ? (
          <LineCharts
            data={data}
            nameKey={"time"}
            dataKey={["solar_load", "grid_load"]}
            showGridVertical={true}
            showGridHorizontal={true}
          />
        ) : (
          <BarCharts
            data={data}
            dataKeys={["solar_load", "grid_load"]}
            nameKey={"time"}
            showGridVertical={true}
            showGridHorizontal={true}
          />
        )}
      </ChartCard>
    );
  };
  const renderChart3 = (data) => {
    return dataType3 == "day" ? (
      <ChartCard1
        paddingLeft={20}
        paddingRight={20}
        bgColor={"#F0F0F0"}
        child2={
          <LineCharts
            data={data}
            nameKey={"time"}
            dataKey={inverterList}
            showGridVertical={true}
            showGridHorizontal={true}
            mT={50}
          />
        }
      ></ChartCard1>
    ) : (
      <ChartCard1
        paddingLeft={20}
        paddingRight={20}
        bgColor={"#F0F0F0"}
        child2={
          <BarCharts
            data={data}
            dataKeys={siteDetail?.inverter_list}
            nameKey={"time"}
            showGridVertical={true}
            showGridHorizontal={true}
          />
        }
      ></ChartCard1>
    );
  };

  return (
    <AppContainer loading={siteDetload}>
      <Box sx={{ p: 3, width: "100%" }}>
        <Col className="siteDetCon">
          <AppHeading
            title={siteName}
            onRefreshClick={handleRefreshClick}
            refreshText={formatDate(sitesDetFetchTime)}
            disabled={siteDetail?.onboarding_station_id === null ? true : false}
            showButton={
              ["Super Admin", "Admin", "Site Manager", "OPS Manager"].includes(
                userInfo?.user?.role
              )
                ? true
                : false
            }
            buttonTitle={"Site Details"}
            onClick={() => {
              navigate(`/view-induction/${siteDetail?.onboarding_station_id}`, {
                state: { onbId: siteDetail?.onboarding_station_id },
              });
            }}
          />

          <SiteDetCard1
            mT={50}
            noOfInverter={siteDetail?.total_inverters}
            activeFault={siteDetail?.active_faults}
            cuf={siteDetail?.cuf}
            perfRatio={siteDetail?.performance_ratio}
            sysSize={siteDetail?.system_size}
            todayYield={siteDetail?.day_power}
            todayRevenue={siteDetail?.day_income}
            tcpr={siteDetail?.tc_performance_ratio}
            stations={siteDetail?.station_hierarchy_data}
            links={siteDetail?.station_hierarchy_links}
            weatherRecord={weatherRecord}
            totalYield={siteDetail?.total_yield}
            totalRevenue={siteDetail?.total_revenue}
            co2Reduction={siteDetail?.reduction_total_co2?.toFixed(2)}
            treesPlanted={siteDetail?.reduction_total_tree?.toFixed(2)}
            setWeatherOpen={(item) => setIsOpen(item)}
            siteComissioningDate={siteDetail.site_comissioning_date}
          />
          {/*Actual vs expected */}
          <ChartCard bgColor={"#F0F0F0"}>
            <Row
              gutter={[10, 10]}
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                <ChartCard
                  title={"Actual Vs Expected Power (kW)"}
                  titleColor={"#454545"}
                  titleFontSize={21}
                  titleFontWeight={400}
                  titleFontFamily={"Lato"}
                  titleLineHeight={"25.2px"}
                  bgColor={"#F0F0F0"}
                ></ChartCard>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={6}
                xl={6}
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                <Row gutter={[10, 10]}>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <AppButton
                      borderRadius={10}
                      buttonTitle={"Daily"}
                      onClick={() => {
                        setDataType1("day");
                      }}
                      backgroundColor={
                        dataType1 === "day" ? "var(--primary_color)" : "#F0F0F0"
                      }
                      textColor={
                        dataType1 === "day"
                          ? "var(--g4)"
                          : "var(--neutral_grey)"
                      }
                      width={"100%"}
                      variant={"outlined"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <AppButton
                      buttonTitle={"Month"}
                      onClick={() => {
                        setDataType1("month");
                      }}
                      backgroundColor={
                        dataType1 === "month"
                          ? "var(--primary_color)"
                          : "#F0F0F0"
                      }
                      textColor={
                        dataType1 === "month"
                          ? "var(--g4)"
                          : "var(--neutral_grey)"
                      }
                      borderRadius={10}
                      width={"100%"}
                      variant={"outlined"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <AppButton
                      buttonTitle={"Year"}
                      onClick={() => {
                        setDataType1("year");
                      }}
                      backgroundColor={
                        dataType1 === "year"
                          ? "var(--primary_color)"
                          : "#F0F0F0"
                      }
                      textColor={
                        dataType1 === "year"
                          ? "var(--g4)"
                          : "var(--neutral_grey)"
                      }
                      borderRadius={10}
                      width={"100%"}
                      variant={"outlined"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {dataType1 === "day" && renderChart1(actualVsExpected)}
            {dataType1 === "month" && renderChart1(actualVsExpected)}
            {dataType1 === "year" && renderChart1(actualVsExpected)}
          </ChartCard>

          {/*Power Mix */}
          <ChartCard bgColor={"#F0F0F0"} mT={50}>
            <Row
              gutter={[10, 10]}
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                <ChartCard
                  title={"Power Mix (kW)"}
                  titleColor={"#454545"}
                  titleFontSize={21}
                  titleFontWeight={400}
                  titleFontFamily={"Lato"}
                  titleLineHeight={"25.2px"}
                  bgColor={"#F0F0F0"}
                ></ChartCard>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={6}
                xl={6}
                style={{ marginTop: 20, marginBottom: 10 }}
              >
                <Row gutter={[10, 10]}>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <AppButton
                      borderRadius={10}
                      buttonTitle={"Daily"}
                      onClick={() => {
                        setDataType2("day");
                      }}
                      backgroundColor={
                        dataType2 === "day" ? "var(--primary_color)" : "#F0F0F0"
                      }
                      textColor={
                        dataType2 === "day"
                          ? "var(--g4)"
                          : "var(--neutral_grey)"
                      }
                      width={"100%"}
                      variant={"outlined"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <AppButton
                      buttonTitle={"Month"}
                      onClick={() => {
                        setDataType2("month");
                      }}
                      backgroundColor={
                        dataType2 === "month"
                          ? "var(--primary_color)"
                          : "#F0F0F0"
                      }
                      textColor={
                        dataType2 === "month"
                          ? "var(--g4)"
                          : "var(--neutral_grey)"
                      }
                      borderRadius={10}
                      width={"100%"}
                      variant={"outlined"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <AppButton
                      buttonTitle={"Year"}
                      onClick={() => {
                        setDataType2("year");
                      }}
                      backgroundColor={
                        dataType2 === "year"
                          ? "var(--primary_color)"
                          : "#F0F0F0"
                      }
                      textColor={
                        dataType2 === "year"
                          ? "var(--g4)"
                          : "var(--neutral_grey)"
                      }
                      borderRadius={10}
                      width={"100%"}
                      variant={"outlined"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {dataType2 === "day" && renderChart2(powerMix)}
            {dataType2 === "month" && renderChart2(powerMix)}
            {dataType2 === "year" && renderChart2(powerMix)}
          </ChartCard>
          {/*Inverter wise performance */}
          <ChartCard bgColor={"#F0F0F0"} mT={50}>
            <Row
              gutter={[10, 10]}
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                <ChartCard
                  title={"Inverter Wise - Performance (kW)"}
                  titleColor={"#454545"}
                  titleFontSize={21}
                  titleFontWeight={400}
                  titleFontFamily={"Lato"}
                  titleLineHeight={"25.2px"}
                  bgColor={"#F0F0F0"}
                ></ChartCard>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={6}
                xl={6}
                style={{ marginTop: 20, marginBottom: 10 }}
              >
                <Row gutter={[10, 10]}>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <AppButton
                      borderRadius={10}
                      buttonTitle={"Daily"}
                      onClick={() => {
                        setDataType3("day");
                      }}
                      backgroundColor={
                        dataType3 === "day" ? "var(--primary_color)" : "#F0F0F0"
                      }
                      textColor={
                        dataType3 === "day"
                          ? "var(--g4)"
                          : "var(--neutral_grey)"
                      }
                      width={"100%"}
                      variant={"outlined"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <AppButton
                      buttonTitle={"Month"}
                      onClick={() => {
                        setDataType3("month");
                      }}
                      backgroundColor={
                        dataType3 === "month"
                          ? "var(--primary_color)"
                          : "#F0F0F0"
                      }
                      textColor={
                        dataType3 === "month"
                          ? "var(--g4)"
                          : "var(--neutral_grey)"
                      }
                      borderRadius={10}
                      width={"100%"}
                      variant={"outlined"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <AppButton
                      buttonTitle={"Year"}
                      onClick={() => {
                        setDataType3("year");
                      }}
                      backgroundColor={
                        dataType3 === "year"
                          ? "var(--primary_color)"
                          : "#F0F0F0"
                      }
                      textColor={
                        dataType3 === "year"
                          ? "var(--g4)"
                          : "var(--neutral_grey)"
                      }
                      borderRadius={10}
                      width={"100%"}
                      variant={"outlined"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {dataType3 === "day" && renderChart3(inverterWisePower)}
            {dataType3 === "month" && renderChart3(inverterWisePower)}
            {dataType3 === "year" && renderChart3(inverterWisePower)}
          </ChartCard>

          <WeatherComponent
            isOpen={isOpen}
            setIsOpen={(item) => setIsOpen(item)}
            data={weatherRecord}
          />
        </Col>
      </Box>
    </AppContainer>
  );
};

export default SiteDetails;
