import { Col, Row } from "antd";
import { Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import {
  CommonCard,
  FilterDropDown,
  LoaderButton,
  DateInput,
} from "../../components";
import dayjs from "dayjs";
import { VendorFormFields, VendorFormVS, stationVendor } from "../../utilities";

export const VendorView = ({ mT, sites, onSubmit }) => {
  const { siteVendorload } = useSelector((state) => state?.sites);
  return (
    <Formik
      initialValues={VendorFormFields}
      validationSchema={VendorFormVS}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
      }}
    >
      {({ errors, status, touched, handleSubmit, setFieldValue, values }) => {
        return (
          <Form className="Auth-form" onSubmit={handleSubmit}>
            <CommonCard mT={mT} title={"Select the Vendor information"}>
              <Row className="preRepbottomCon" gutter={[0, 50]}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <FilterDropDown
                    label={"Sites"}
                    value={values?.siteName || null}
                    placeholder={"Select Site"}
                    vldName={"siteName"}
                    list={sites.map((data) => data?.label)}
                    onSelect={(item) => {
                      setFieldValue("siteName", item);
                    }}
                  />

                  <DateInput
                    label={"Site Vendor Date"}
                    value={values?.vendorDate ? dayjs(values?.vendorDate) : ""}
                    vldName={"vendorDate"}
                    placeholder={"Enter Site Vendor Date"}
                    onSelect={(item) => {
                      setFieldValue("vendorDate", item);
                    }}
                  />

                  <div className="Auth-form-content">
                    <LoaderButton
                      title={"Find"}
                      onClick={handleSubmit}
                      isLoading={siteVendorload}
                    />
                  </div>
                </Col>

                <Col className="imgCon" xs={24} sm={24} md={16} lg={16} xl={16}>
                  <img
                    className="preReportImg"
                    height={"90%"}
                    width={"50%"}
                    src={stationVendor}
                  />
                </Col>
              </Row>
            </CommonCard>
          </Form>
        );
      }}
    </Formik>
  );
};
