import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AppHeading, PreReportCard } from "../../components";
import { generateReportAction, getAllSitesAction } from "../../redux/actions";
import { networkText } from "../../utilities";

import axios from "axios";
import { saveAs } from "file-saver";
import Box from "@mui/material/Box";
import { fileFormats } from "../../utilities";
const Reports = ({ navigation }) => {
  const dispatch = useDispatch(null);
  const navigate = useNavigate();
  const { sites } = useSelector((state) => state?.sites);

  const [viewOption, setViewOption] = useState("daily");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState("pdf");
  const [dataType, setDataType] = useState(null);

  const [dataTypes] = useState([
    { value: "day", label: "daily" },
    { value: "month", label: "monthly" },
    { value: "year", label: "yearly" },
  ]);

  const getValue = (label) => {
    const type = dataTypes.find((type) => type?.label === label);

    return type ? type?.value : null;
  };

  useEffect(() => {
    getAllSites();
  }, []);

  const getAllSites = () => {
    if (navigator.onLine) {
      const requestBody = {
        values: null,
        onSuccess: (res) => {},
        onFailure: (error) => {},
      };
      dispatch(getAllSitesAction(requestBody));
    } else {
      toast.error(networkText);
    }
  };

  const onSubmit = async (values) => {
    if (navigator.onLine) {
      const requestBody = {
        station_names:
          values?.reportFormat === "pdf" ? values?.site : values?.sites,
        start_date: values?.timePeriod[0],
        end_date: values?.timePeriod[1],
        format: values?.reportFormat,
        date: values?.date,
        dataType: values.dataType,
      };

      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
            if (selectedFormat == "pdf") {
              generatePdf(res);
            } else {
              generateXlsx(res);
            }

            toast.success("Report Generated Successfully");
          }
        },
        onFailure: (error) => {
          // console.log(error?.response, "errr");
          toast.error("No data found");
        },
      };

      dispatch(generateReportAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const generatePdf = async (res) => {
    const pdfBlob = new Blob([res], { type: "application/pdf" });
    saveAs(pdfBlob, "generated.pdf");
  };

  const generateXlsx = async (res) => {
    const xlsxBlob = new Blob([res], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(xlsxBlob, "generated.xlsx");
  };
  return (
    <Col className="reportCon">
      <Box sx={{ p: 3, width: "100%" }}>
        <AppHeading title={"Reports"} />
        <PreReportCard
          selectedDate={selectedDate}
          setSelectedDate={(item) => setSelectedDate(item)}
          setFormat={(item) => setSelectedFormat(item)}
          viewOption={viewOption}
          setViewOption={(item) => setViewOption(item)}
          sites={
            sites &&
            sites.map((entry) => ({
              label: entry?.station_name,
              value: entry?.station_code,
            }))
          }
          formats={fileFormats}
          onSubmit={(item) => onSubmit(item)}
          dataType={dataType}
          setDataType={(item) => setDataType(item)}
          dataTypes={dataTypes}
          getValue={getValue}
        />
      </Box>
    </Col>
  );
};

export default Reports;
