import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AppHeading,
  DevicesDropDown,
  InductionStep0Comp,
  InductionStep1Comp,
  InductionStep2Comp,
  InductionStep3Comp,
} from "../../components";
import {
  getInductionDetails,
  updateFormStatusAction,
} from "../../redux/actions";
import { networkText } from "../../utilities";

const ViewInduction = ({ navigation }) => {
  const location = useLocation();
  const dispatch = useDispatch(null);
  // const [id, SetId] = useState(null);
  const navigate = useNavigate();
  const [showData, setShowData] = useState(false);
  const [status, setStatus] = useState(null);
  const params = useParams();
  const id = params?.id || "";

  const steps = [
    "Site Details",
    "System Details",
    "General Details",
    "Inverter Details",
  ];

  const [activeStep, setActiveStep] = useState(0);
  const { inductionInfo, organizations } = useSelector(
    (state) => state?.inductions
  );
  const { userInfo } = useSelector((state) => state?.auth);

  const updateStatus = async (id, action) => {
    if (navigator.onLine) {
      const requestBody = {
        id: id,
        action: action,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          toast.success("Status updated successfully");
        },
        onFailure: (error) => {},
      };
      dispatch(updateFormStatusAction(body));
    } else {
      toast.error(networkText);
    }
  };
  useEffect(() => {
    setStatus(inductionInfo?.status);
    // if (id) {
    //   getDetails(id);
    // }
  }, [inductionInfo]);

  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);

  const getDetails = (onboardingId) => {
    const requestBody = {
      id: onboardingId,
    };

    const body = {
      values: requestBody,
      onSuccess: async (res) => {
        if (res) {
          // SetId(res?.data?.id);
          setShowData(true);
        }
      },
      onFailure: (error) => {
        toast.error(error?.data?.message);
      },
    };

    dispatch(getInductionDetails(body));
  };

  const handleClickStep = (step) => {
    setActiveStep(step);
  };

  const handleActionClick = (id, action) => {
    updateStatus(id, action);
  };

  const options = ["Approved", "Rejected"];
  return (
    <Box sx={{ p: 3, width: "100%" }}>
      <Col className="inductionCon">
        <AppHeading title={"Inductions"} />
        <Box sx={{ width: "100%", marginBottom: "40px" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} onClick={() => handleClickStep(index)}>
                <StepLabel
                  StepIconProps={{
                    style: {
                      cursor: "pointer",
                      color:
                        activeStep > index
                          ? "var(--primary_color)"
                          : activeStep === index
                          ? "var(--primary_color)"
                          : "grey",
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {userInfo.user.role === "Admin" ||
        userInfo.user.role === "Super Admin" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "5px",
            }}
          >
            {status == "Approved" && (
              <div>
                <h6 style={{ color: "#00000" }}>Form is already Approved.</h6>
              </div>
            )}
            {status !== "Approved" && (
              <div>
                <h6 style={{ color: "#00000" }}>
                  Please make sure data is approved by vendor before accepting
                  the induction form.
                </h6>
              </div>
            )}
            <div
              style={{ color: "#ecec06", paddingBottom: "5px", width: "150px" }}
            >
              {status !== "Approved" && (
                <DevicesDropDown
                  value={status}
                  showSearch={false}
                  list={options}
                  placeholder={"Approve/Reject"}
                  borderColor={"var(--color-wateen)"}
                  onSelect={(item) => {
                    handleActionClick(id, item);
                    setStatus(item);
                  }}
                />
              )}
            </div>
          </div>
        ) : null}
        {activeStep === 0 && (
          <InductionStep0Comp
            data={inductionInfo}
            handleClickStep={() => handleClickStep(1)}
          />
        )}
        {activeStep === 1 && (
          <InductionStep1Comp
            data={inductionInfo}
            handleClickStep={(step) => handleClickStep(step)}
            stepper={activeStep}
            setStepper={(val) => setActiveStep(val)}
          />
        )}
        {activeStep === 2 && (
          <InductionStep2Comp
            data={inductionInfo}
            handleClickStep={(step) => handleClickStep(step)}
            stepper={activeStep}
            setStepper={(val) => setActiveStep(val)}
          />
        )}
        {activeStep === 3 && (
          <InductionStep3Comp
            data={inductionInfo}
            handleClickStep={(step) => handleClickStep(step)}
            stepper={activeStep}
            setStepper={(val) => setActiveStep(val)}
            id={id}
          />
        )}
      </Col>
    </Box>
  );
};

export default ViewInduction;
