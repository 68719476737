import { Select } from "antd";
import { ErrorMessage } from "formik";
import React from "react";

const { Option } = Select;

export const FilterDropDown = ({
  label,
  vldName,
  mT,
  placeholder,
  list = [],
  onSelect,
  onInput,
  value,
  mode = null,
}) => {
  const dropDownStyles = {
    boxShadow: "0 7px 15px var(--gr10)",
    width: "100%",
    marginTop: "5px",
    borderRadius: "5px",
  };

  const handleBlur = (e) => {
    const inputValue = e.target.value;
    if (onInput) {
      onInput(inputValue);
    }
  };

  return (
    <div
      style={{
        marginTop: mT,
      }}
      className="appInputCon"
    >
      <div className={`form-group mt-1`}>
        <label className="labelText">{label}</label>

        <Select
          mode={mode}
          showSearch
          onChange={onSelect}
          onBlur={handleBlur}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          size="large"
          placeholder={placeholder}
          value={value}
          style={dropDownStyles}
          bordered={false}
        >
          {list.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
        {vldName && (
          <ErrorMessage name={vldName} component="div" className="error" />
        )}
      </div>
    </div>
  );
};
