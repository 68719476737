import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AppButton, CommonCard } from "../../components";
import { useDispatch } from "react-redux";

export const VendorTable = ({ data, onSubmit }) => {
  const dispatch = useDispatch();

  const columns = [
    {
      field: "production",
      headerName: "Production",
      width: 250,
    },
    {
      field: "income",
      headerName: "Income",
      width: 250,
    },
    {
      field: "grid_power",
      headerName: "Grid Power",
      width: 250,
    },
    {
      field: "consumption",
      headerName: "Consumption",
      width: 250,
    },
  ];

  const rows = [
    {
      id: 1,
      production: data?.production,
      income: data?.income,
      grid_power: data?.grid_power,
      consumption: data?.consumption,
    },
  ];
  return (
    <CommonCard
      title={"View the Vendor Details"}
      buttonTitle={"Submit"}
      onClick={onSubmit}
    >
      <div style={{ padding: "20px" }}>
        <DataGrid rows={rows} columns={columns} autoHeight pageSize={10} />
      </div>
    </CommonCard>
  );
};

export default VendorTable;
