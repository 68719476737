import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppHeading } from "../../components";
import { networkText, steps } from "../../utilities";

import {
  addInductionStation,
  addSupplierAction,
  getInductionDetails,
  getOrgAction,
} from "../../redux/actions";
import { toast } from "react-toastify";
import { date } from "yup";
import { SettingsComp } from "../../components/CustomComponents/SettingsComp";
import { editFactorAction, getFactorAction } from "../../redux/actions";
import { AddSupplierComp } from "../../components/CustomComponents/AddSupplierComp";

const Settings = ({ navigation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch(null);
  const location = useLocation();

  const { inductionInfo, organizations, suppliers } = useSelector(
    (state) => state?.inductions
  );
  const { factor, factorLoad, co2Factor, treeFactor } = useSelector(
    (state) => state?.factor
  );
  const data = {
    client_data: [],
    co2_factor: co2Factor,
    tree_factor: treeFactor,
  };

  const [addData, setAddData] = useState([]);

  useEffect(() => {
    getFactor();
    getOrganizations();
    if (suppliers.length > 0) {
      suppliers.forEach((supplier) => {
        data.client_data.push({
          name: supplier?.name,
          tariff: supplier?.terriff,
        });
      });
    }
  }, []);

  const getFactor = () => {
    if (navigator.onLine) {
      const requestBody = {
        values: null,
        onSuccess: (res) => {},
        onFailure: () => {},
      };
      dispatch(getFactorAction(requestBody));
    } else {
      toast.error(networkText);
    }
  };
  const onClickSave = async (values) => {
    if (navigator.onLine) {
      const requestBody = {
        co2Factor: values?.co2_factor,
        treeFactor: values?.tree_factor,
        gridSupplierName: values?.gridSupplName,
        tariff: values?.tariff,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
            console.log(res);
            getFactor();
            getOrganizations();
            toast.success("Save Detail Successfully");
          }
        },
        onFailure: (error) => {},
      };

      dispatch(editFactorAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const getOrganizations = async () => {
    if (navigator.onLine) {
      const body = {
        onSuccess: async (res) => {},
        onFailure: (res) => {},
      };

      dispatch(getOrgAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const onAdd = async (values) => {
    if (navigator.onLine) {
      const requestBody = {
        name: values?.name,
        tariff: values?.tariff,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
            console.log(res);
            getFactor();
            getOrganizations();
            toast.success("Add Supplier Successfully");
          }
        },
        onFailure: (error) => {},
      };

      dispatch(addSupplierAction(body));
    } else {
      toast.error(networkText);
    }
  };
  console.log("data", data);
  return (
    <Col className="inductionCon">
      <Box sx={{ p: 3, width: "100%" }}>
        <AppHeading title={"Settings"} />
        <SettingsComp
          data={data}
          supp={suppliers && suppliers?.map((entry) => entry.name)}
          suppData={suppliers}
          onSave={(values) => onClickSave(values)}
          co2Factor={co2Factor}
          treeFactor={treeFactor}
        />

        <AddSupplierComp onSave={(values) => onAdd(values)} />
      </Box>
    </Col>
  );
};

export default Settings;
