import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppHeading, ContactFormCard } from "../../components";
import { addContactAction } from "../../redux/actions";
import { networkText } from "../../utilities";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";

const ContactUs = ({ navigation }) => {
  const dispatch = useDispatch(null);
  const location = useLocation();

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.Tawk_API &&
      typeof window.Tawk_API.showWidget === "function"
    ) {
      if (location?.pathname === "/contact-us") {
        window.Tawk_API.showWidget();
        return () => {
          window.Tawk_API.hideWidget();
        };
      }
    }
  }, []);

  const onSubmit = async (values) => {
    if (navigator.onLine) {
      const requestBody = {
        subject: values?.subject,
        description: values?.message,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
            toast.success("Message sent successfully!!");
          }
        },
        onFailure: (error) => {},
      };

      dispatch(addContactAction(body));
    } else {
      toast.error(networkText);
    }
  };

  return (
    <Col>
      <Box sx={{ p: 3, width: "100%" }}>
        <AppHeading title={"Contact Us"} />

        <Row className="rowCard" gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <ContactFormCard
              onSubmit={(values) => {
                onSubmit(values);
              }}
            />
          </Col>
        </Row>
        <TawkMessengerReact
          propertyId="64a408c9cc26a871b02644a5"
          widgetId="1h4gc4ltc"
        />
      </Box>
    </Col>
  );
};

export default ContactUs;
