import { Row, Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import {
  AppContainer,
  AppHeading,
  ChartCard,
  EquipmentCard,
  LineCharts,
} from "../../../components";
import { getDeviceDetail } from "../../../redux/actions";
import {
  networkText,
  openInNewTab,
  getMonthsLeft,
  downloadIcon,
} from "../../../utilities";

import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import moment from "moment";
import Box from "@mui/material/Box";

const InverterDetails = ({ navigation }) => {
  const dispatch = useDispatch(null);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.data?.id || null;
  const inverterName = location?.state?.data?.device_name || "Inverter Details";
  const { deviceDetail, deviceDetLoad } = useSelector(
    (state) => state?.devices
  );

  useEffect(() => {
    getData(id);
  }, []);
  const getData = async (value) => {
    if (navigator.onLine) {
      const requestBody = {
        device_id: value,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
          }
        },
        onFailure: (error) => {},
      };

      dispatch(getDeviceDetail(body));
    } else {
      toast.error(networkText);
    }
  };

  const downloadData = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const mainWorksheet = workbook.addWorksheet("Inverter Datasheet");

    // Style for header (A1 cell)
    mainWorksheet.getCell("A1").font = {
      bold: true,
      size: 20,
      color: { argb: "FFFFFF" },
    };
    mainWorksheet.getCell("A1").alignment = { horizontal: "center" };

    const columnA = mainWorksheet.getColumn("A");
    columnA.width = 40;

    const columnB = mainWorksheet.getColumn("B");
    columnB.width = 40;

    // Style for rows
    const defaultRowStyle = {
      alignment: { horizontal: "center" },
      font: { bold: false, size: 14 },
    };

    const rows = [
      ["ID", data?.id],
      ["Device SN", data?.device_sn],
      ["Device Name", data?.device_name],
      ["Device Type ID", data?.device_type_id],
      ["Size", `${data?.capacity} kW`],
      ["Site Name", data?.station_name],
      ["Commisoning Date", data?.commisioning_date],
      ["Supplier", data?.supplier],
      ["Remaining Warranty", `${data?.remaining_warranty} years`],
      ["Active Power", `${data?.active_power} kW`],
      ["Inverter Efficiency", `${data?.inverter_efficiency}%`],
      ["Internal Temperature", `${data?.internal_temperature} °C`],
      ["Grid Frequency", `${data?.grid_frequency} Hz`],
      ["Curtailment", `${data?.curtailment}`],
      ["Total Fault", data?.total_fault],
      ["Mismatch Loss", data?.mismatch_loss],
      ["Start Time", data?.start_time],
      ["Shutdown Time", data?.shutdown_time],
      ["Downtime", data?.downtime],
    ];

    rows.forEach((row) => {
      const newRow = mainWorksheet.addRow(row);
      newRow.eachCell((cell) => {
        cell.style = defaultRowStyle;
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveBufferAsExcelFile(buffer, "inverter-datasheet.xlsx");
  };

  const saveBufferAsExcelFile = (buffer, fileName) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, fileName);
  };
  return (
    <AppContainer loading={deviceDetLoad}>
      <Box sx={{ p: 3, width: "100%" }}>
        <Col className="equipCon">
          <Row gutter={[16, 16]} align="middle">
            <Col span={12} className="equipCon">
              <AppHeading title={inverterName} />
            </Col>
            <Col span={12} className="downloadCon">
              <IconButton
                sx={{
                  marginLeft: "4px",
                  backgroundColor: "var(--neutral_light)",
                  borderRadius: "4px",
                  padding: "10px",
                  "&:hover": {
                    boxShadow: "0px 5px 14px 0px var(--box_shadow)",
                    backgroundColor: "var(--neutral_light)",
                  },
                }}
                onClick={() => downloadData(deviceDetail)}
              >
                <img height={22} width={22} src={downloadIcon} />
              </IconButton>
            </Col>
          </Row>

          <EquipmentCard
            supplier={deviceDetail?.supplier}
            id={deviceDetail?.id}
            name={inverterName}
            size={deviceDetail?.capacity}
            comissionDate={deviceDetail?.commisioning_date}
            warranty={getMonthsLeft(deviceDetail?.remaining_warranty)}
            startTime={deviceDetail?.start_time}
            shutdownTime={deviceDetail?.shutdown_time}
            downTime={deviceDetail?.downtime}
            currentPower={deviceDetail?.active_power}
            totalCurt={deviceDetail?.curtailment}
            inverterTemp={deviceDetail?.internal_temperature}
            gridFreq={deviceDetail?.grid_frequency}
            inverterEff={deviceDetail?.inverter_efficiency}
            totalFault={deviceDetail?.total_fault}
            mmptMismatch={deviceDetail?.mismatch_loss}
          />

          <ChartCard title={"String - Voltage (V)"} mT={50}>
            <LineCharts
              data={deviceDetail?.device_data}
              nameKey={"time"}
              dataKey={[
                "pv1_u",
                "pv2_u",
                "pv3_u",
                "pv4_u",
                "pv5_u",
                "pv6_u",
                "pv7_u",
                "pv8_u",
                "pv9_u",
                "pv10_u",
                "pv11_u",
                "pv12_u",
                "pv13_u",
                "pv14_u",
                "pv15_u",
                "pv16_u",
                "pv17_u",
                "pv18_u",
                "pv19_u",
                "pv20_u",
                "pv21_u",
                "pv22_u",
                "pv23_u",
                "pv24_u",
              ]}
              showGridVertical={true}
              showGridHorizontal={true}
            />
          </ChartCard>
          <ChartCard title={"String - Current (A)"} mT={50}>
            <LineCharts
              data={deviceDetail?.device_data}
              nameKey={"time"}
              dataKey={[
                "pv1_i",
                "pv2_i",
                "pv3_i",
                "pv4_i",
                "pv5_i",
                "pv6_i",
                "pv7_i",
                "pv8_i",
                "pv9_i",
                "pv10_i",
                "pv11_i",
                "pv12_i",
                "pv13_i",
                "pv14_i",
                "pv15_i",
                "pv16_i",
                "pv17_i",
                "pv18_i",
                "pv19_i",
                "pv20_i",
                "pv21_i",
                "pv22_i",
                "pv23_i",
                "pv24_i",
              ]}
              showGridVertical={true}
              showGridHorizontal={true}
            />
          </ChartCard>
          <ChartCard title={"MPPT Power Curve (Watts)"} mT={50}>
            <LineCharts
              data={deviceDetail?.device_data}
              nameKey={"time"}
              dataKey={[
                "mppt_1_cap",
                "mppt_2_cap",
                "mppt_3_cap",
                "mppt_4_cap",
                "mppt_5_cap",
                "mppt_6_cap",
                "mppt_7_cap",
                "mppt_8_cap",
                "mppt_9_cap",
                "mppt_10_cap",
                "mppt_11_cap",
                "mppt_12_cap",
                "mppt_13_cap",
                "mppt_14_cap",
                "mppt_15_cap",
                "mppt_16_cap",
                "mppt_17_cap",
                "mppt_18_cap",
                "mppt_19_cap",
                "mppt_20_cap",
                "mppt_21_cap",
                "mppt_22_cap",
                "mppt_23_cap",
                "mppt_24_cap",
              ]}
              showGridVertical={true}
              showGridHorizontal={true}
            />
          </ChartCard>
        </Col>
      </Box>
    </AppContainer>
  );
};

export default InverterDetails;
