//Types for Login
export const AUTH_LOGIN = "auth/login";
//Types for Logout
export const AUTH_LOGOUT = "auth/logout";
//Types for Forgot Password
export const FORGOT_PASS = "auth/forgot-password";
//Types for Change Password
export const CHANGE_PASS = "auth/change-password";
//Types for Reset Password
export const RESET_PASS = "auth/change-password";
