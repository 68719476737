import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AppContainer,
  AppDropDown,
  AppHeading,
  DevicesDropDown,
  EquipmentTable,
} from "../../../components";
import { filterDevicesAction, getDevicesAction } from "../../../redux/actions";
import {
  equipColumns,
  formatDate,
  networkText,
  oemOptions,
} from "../../../utilities";
import { Box } from "@mui/material";

const Equipments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [localDeviceFilter, setLocalDeviceFilter] = useState("All OEM"); // Local state for deviceFilter

  const { devices, deviceLoad, filteredDevices, devicesFetchTime } =
    useSelector((state) => state.devices);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (navigator.onLine) {
      const body = {
        onSuccess: async (res) => {},
        onFailure: (res) => {},
      };

      dispatch(getDevicesAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const handleRowClick = (data) => {
    if (data?.device_type_id == 1) {
      navigate("/inverter-details", { state: { data } });
    } else {
      navigate("/device-details", { state: { data } });
    }
  };
  const handleRefreshClick = () => {
    window.location.reload();
  };

  const handleFilterChange = (item) => {
    setLocalDeviceFilter(item);
    dispatch(filterDevicesAction(item));
  };

  return (
    <AppContainer loading={deviceLoad}>
      <Box sx={{ p: 3, width: "99%" }}>
        <Col className="equipCon">
          <AppHeading
            title={"Devices"}
            onRefreshClick={handleRefreshClick}
            refreshText={formatDate(devicesFetchTime)}
            titleFontFamily={"Lato"}
            titleFontWeight={400}
            titleFontSize={30}
            titleLineHeight={"36px"}
            titleLetterSpacing={0.25}
            titleColor={"var(--neutral_grey)"}
          />

          <Col xs={10} sm={10} md={3} lg={3} xl={3}>
            <DevicesDropDown
              value={localDeviceFilter}
              list={oemOptions}
              borderColor={"rgba(224, 224, 224, 1)"}
              onSelect={handleFilterChange}
            />
          </Col>

          {filteredDevices && (
            <EquipmentTable
              handleRowClick={(item) => handleRowClick(item)}
              rows={filteredDevices}
              columns={equipColumns}
            />
          )}
        </Col>
      </Box>
    </AppContainer>
  );
};

export default Equipments;
