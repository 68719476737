import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Select } from "antd";

export const AppDropDown = ({
  label,
  vldName,
  mT,
  placeholder,
  list = [],
  onSelect,
  value,
}) => {
  const dropDownStyles = {
    boxShadow: "0 7px 15px var(--gr10)",
    width: "100%",
    marginTop: "5px",
    borderRadius: "5px",
    height: "42px" || "auto",
  };

  return (
    <div
      style={{
        marginTop: mT,
      }}
      className="appInputCon"
    >
      <div className={`form-group mt-1`}>
        <label className="labelText">{label}</label>

        <Select
          onChange={(selectedValue) => {
            onSelect(selectedValue);
          }}
          options={list}
          size={"large"}
          placeholder={placeholder}
          value={value}
          style={dropDownStyles}
          bordered={false}
        />
        {vldName && (
          <ErrorMessage name={vldName} component="div" className="error" />
        )}
      </div>
    </div>
  );
};
