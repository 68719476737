import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AlarmCard,
  AppContainer,
  AppHeading,
  BarCharts,
  ChartCard1,
  SiteCard,
  SpecsInfo,
} from "../../components";
import {
  getOperationsAction,
  getOperationsTicketsAction,
} from "../../redux/actions";
import {
  alarmIcon,
  bluBell,
  disconectIcon,
  faultIcon,
  grnBell,
  healthyIcon,
  networkText,
  orngAlarmIcon,
  redAlarmIcon,
  ticketIcon,
  yloAlarmIcon,
  yloBell,
} from "../../utilities";
import { Box } from "@mui/material";
import { CustomTextBox } from "../../components/CustomComponents/CustomTextBox";

const Operations = ({ navigation }) => {
  const dispatch = useDispatch(null);
  const navigate = useNavigate();
  const { operations, operationLoad, oemTicket } = useSelector(
    (state) => state?.operations
  );

  useEffect(() => {
    getData();
    getOnmTicktsData();
  }, []);

  const handleAlarmCardClick = (type) => {
    navigate("/alarms", { state: { type } });
  };
  const handleSiteCardClick = (type) => {
    navigate("/sites", { state: { type } });
  };
  const handleTicketCardClick = (type) => {
    navigate("/tickets", { state: { type } });
  };

  const getData = async (values) => {
    if (navigator.onLine) {
      const body = {
        onSuccess: async (res) => {},
        onFailure: (res) => {},
      };

      dispatch(getOperationsAction(body));
    } else {
      toast.error(networkText);
    }
  };
  const getOnmTicktsData = async (values) => {
    if (navigator.onLine) {
      const body = {
        onSuccess: async (res) => {},
        onFailure: (res) => {},
      };

      dispatch(getOperationsTicketsAction(body));
    } else {
      toast.error(networkText);
    }
  };

  return (
    <AppContainer loading={operationLoad}>
      <Box sx={{ p: 3, width: "100%" }}>
        <Col className="operationCon">
          <AppHeading
            title={"Operations & Maintenance"}
            titleColor={"#454545"}
            titleFontSize={30}
            titleFontWeight={400}
            titleFontFamily={"Lato"}
            titleLineHeight={"36px"}
            titleLetterSpacing={0.25}
          />
          <div style={{ margin: "13px 0px 5px 0px" }}>
            <CustomTextBox
              text={"Sites"}
              textFontColor={"var(--g11)"}
              textFontSize={21}
              textFontWeight={400}
              textFontFamily={"Lato"}
              textLineHeight={"25.2px"}
              textLetterSpacing={0}
            ></CustomTextBox>
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <SiteCard
                title={"All Sites"}
                value={operations?.total_stations}
                onClick={() => handleSiteCardClick("all")}
                titleColor={"var(--g11)"}
                primaryColor={"var(--primary_color)"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <SiteCard
                title={"Gold Sites"}
                value={operations?.gold_stations}
                onClick={() => handleSiteCardClick("gold")}
                titleColor={"var(--g11)"}
                primaryColor={"var(--primary_color)"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <SiteCard
                title={"Platinum Sites"}
                value={operations?.platinum_stations}
                onClick={() => handleSiteCardClick("platinum")}
                titleColor={"var(--g11)"}
                primaryColor={"var(--primary_color)"}
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <SiteCard
                title={"Silver Sites"}
                value={operations?.silver_stations}
                onClick={() => handleSiteCardClick("silver")}
                titleColor={"var(--g11)"}
                primaryColor={"var(--primary_color)"}
              />
            </Col>
          </Row>
          <div style={{ margin: "13px 0px 5px 0px" }}>
            <CustomTextBox
              text={"Status"}
              textFontColor={"var(--g11)"}
              textFontSize={21}
              textFontWeight={400}
              textFontFamily={"Lato"}
              textLineHeight={"25.2px"}
              textLetterSpacing={0}
            ></CustomTextBox>
          </div>
          <Row style={{}} gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <SpecsInfo
                title={"Healthy"}
                value={operations?.real_health_states[0]?.count}
                onClick={() => handleSiteCardClick("On")}
                color={"#00BB27"}
                iconSrc={healthyIcon}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <SpecsInfo
                title={"Faulty"}
                value={operations?.real_health_states[1]?.count}
                onClick={() => handleSiteCardClick("Disable")}
                color={"#FF8000"}
                iconSrc={faultIcon}
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <SpecsInfo
                title={"Disconnected"}
                value={operations?.real_health_states[2]?.count}
                onClick={() => handleSiteCardClick("Off")}
                color={"#FF0000"}
                iconSrc={disconectIcon}
              />
            </Col>
          </Row>
          <div style={{ margin: "13px 0px 5px 0px" }}>
            <CustomTextBox
              text={"Tickets"}
              textFontColor={"var(--g11)"}
              textFontSize={21}
              textFontWeight={400}
              textFontFamily={"Lato"}
              textLineHeight={"25.2px"}
              textLetterSpacing={0}
            ></CustomTextBox>
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <SpecsInfo
                title={"NOC Tickets"}
                value={oemTicket?.noc_tickets || "0"}
                onClick={() => handleTicketCardClick("es_noc")}
                iconSrc={ticketIcon}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <SpecsInfo
                title={"Infra Tickets"}
                value={oemTicket?.infra_tickets || "0"}
                onClick={() => handleTicketCardClick("es_infra")}
                iconSrc={ticketIcon}
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <SpecsInfo
                title={"Project Tickets"}
                value={oemTicket?.project_tickets || "0"}
                onClick={() => handleTicketCardClick("es_projects")}
                iconSrc={ticketIcon}
              />
            </Col>
          </Row>
          <div style={{ margin: "13px 0px 5px 0px" }}>
            <CustomTextBox
              text={"Alarms"}
              textFontColor={"var(--g11)"}
              textFontSize={21}
              textFontWeight={400}
              textFontFamily={"Lato"}
              textLineHeight={"25.2px"}
              textLetterSpacing={0}
            ></CustomTextBox>
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <AlarmCard
                title={"All Alarms"}
                iconSrc={alarmIcon}
                value={operations?.total_alarms}
                onClick={() => handleAlarmCardClick("all")}
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <AlarmCard
                title={"Critical Alarms"}
                value={operations?.critical_alarms}
                onClick={() => handleAlarmCardClick("critical")}
                iconSrc={redAlarmIcon}
                color={"var(--r1)"}
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <AlarmCard
                title={"Major Alarms"}
                iconSrc={orngAlarmIcon}
                value={operations?.major_alarms}
                onClick={() => handleAlarmCardClick("major")}
                color={"var(--y3)"}
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <AlarmCard
                title={"Minor Alarms"}
                iconSrc={yloAlarmIcon}
                value={operations?.minor_alarms}
                onClick={() => handleAlarmCardClick("minor")}
                color={"var(--y4)"}
              />
            </Col>
          </Row>
          <ChartCard1
            title={"Site Wise Alarms"}
            mT={20}
            child2={
              <BarCharts
                title={"Performance Ratio Comparison"}
                data={operations?.alarms_by_station}
                xAxisLabel={"Site Name"}
                yAxisLabel={"No of Alarms"}
                nameKey={"station_name"}
                dataKey1={"critical"}
                dataKey2={"major"}
                dataKey3={"minor"}
                fill1={"var(--r1)"}
                fill2={"var(--y3)"}
                fill3={"var(--y4)"}
                showGridHorizontal={true}
                showGridVertical={true}
              />
            }
          ></ChartCard1>
        </Col>
      </Box>
    </AppContainer>
  );
};

export default Operations;
