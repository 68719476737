import { Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlarmComp, AppHeading, AddAlarmComp } from "../../components";
import { addAlarmAction, getAllSitesAction } from "../../redux/actions";
import { toast } from "react-toastify";
import { networkText } from "../../utilities";
import { Box } from "@mui/material";
const AddAlarm = ({ navigation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch(null);
  const { sites } = useSelector((state) => state?.sites);

  useEffect(() => {
    getAllSites();
  }, []);

  const getAllSites = () => {
    if (navigator.onLine) {
      const requestBody = {
        values: null,
        onSuccess: (res) => {},
        onFailure: () => {},
      };
      dispatch(getAllSitesAction(requestBody));
    } else {
      toast.error(networkText);
    }
  };

  const onSubmit = async (values) => {
    if (navigator.onLine) {
      const requestBody = {
        name: values?.event,
        station_name: values?.siteName,
        level: values?.priority,
        status: values?.alarmStatus,
      };

      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
            navigate("/alarms");
          }
        },
        onFailure: (error) => {
          toast.error(error?.data?.message);
        },
      };

      dispatch(addAlarmAction(body));
    } else {
      toast.error(networkText);
    }
  };

  return (
    <Col className="inductionCon">
      <Box sx={{ p: 3, width: "100%" }}>
        <AppHeading title={"Add Alarm"} />
        <AddAlarmComp
          onSubmit={(values) => {
            onSubmit(values);
          }}
          sites={sites && sites?.map((entry) => entry.station_name)}
        />
      </Box>
    </Col>
  );
};

export default AddAlarm;
