import Box from "@mui/material/Box";
import { Col, Row } from "antd";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AppContainer,
  AppHeading,
  ChartCard,
  CommonCard,
  CompBarCharts,
  DevicesDropDown,
  MapComponent,
  OverviewCard1,
  OverviewCard2,
  OverviewCommonCard,
  PieCharts,
  AppButton,
} from "../../components";
import { OverviewCard3 } from "../../components/Cards/OverviewCard3";
import { CustomTextBox } from "../../components/CustomComponents/CustomTextBox";
import { getOverviewAction, prComparisonAction } from "../../redux/actions";
import {
  PRComparisonFormFields,
  PRComparisonVS,
  SolarPanelBackground,
  co2Blue,
  installBaseIcon,
  networkText,
  overviewBg,
  pieChartColors,
  revenue,
  trees,
  whiteAlarmIcon,
  liveSystemIcon,
} from "../../utilities";

const Overview = ({ navigation }) => {
  const dispatch = useDispatch(null);
  const navigate = useNavigate();
  const {
    overview,
    overviewLoad,
    prCitiesList,
    prRegionsList,
    prStationList,
    prMountList,
    prSiteList,
    prComparison,
  } = useSelector((state) => state?.overview);
  const { userInfo } = useSelector((state) => state?.auth);
  const [moutningTab, setMountingTab] = useState(null);
  const [brandTab, setBrandTab] = useState(null);
  const [selectedChart, setSelectedChart] = useState(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState("Daily");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedSlab, setSelectedSlab] = useState(null);
  useEffect(() => {
    getData();
  }, []);

  const getData = async (values) => {
    if (navigator.onLine) {
      const body = {
        onSuccess: async (res) => {
          // setBrandTab(res?.data?.brand_wise[0]);
          // setMountingTab(res?.data?.mount_wise[0]);
        },
        onFailure: (res) => {},
      };

      dispatch(getOverviewAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const onPrComparison = async (
    city,
    region,
    station_name,
    mount_list,
    site_breakup_list,
    period
  ) => {
    if (navigator.onLine) {
      const requestBody = {
        city: city,
        region: region === "Region" ? "" : region,
        station_name: station_name === "Station" ? "" : station_name,
        mount_list: mount_list === "Mount" ? "" : mount_list,
        site_breakup_list:
          site_breakup_list === "Type" ? "" : site_breakup_list,
        period: period || selectedTimeRange,
      };

      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
          }
        },
        onFailure: (error) => {
          toast.error("Failed to update site status");
        },
      };

      dispatch(prComparisonAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const handleRefreshClick = () => {
    window.location.reload();
  };

  // Function to format revenue value in million (M) or billion (B)
  const formatRevenue = (value) => {
    if (value !== undefined && value !== null) {
      if (value >= 1000000000) {
        return (value / 1000000000).toFixed(2) + "B";
      } else if (value >= 1000000) {
        return (value / 1000000).toFixed(2) + "M";
      } else {
        return value.toLocaleString();
      }
    } else {
      return "N/A"; // or any default value you prefer
    }
  };
  const renderChart = (data) => {
    return (
      <CompBarCharts
        data={data}
        xAxisLabel={"Site Name"}
        yAxisLabel={"Perf Ratio (%)"}
        nameKey={"station_name"}
        dataKey1={"Perf_Ratio"}
        showGridHorizontal={true}
        showGridVertical={true}
        bgColor={"#F0F0F0"}
      />
    );
  };

  return (
    <AppContainer loading={overviewLoad} style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: -1,
          width: "100%",
          height: "30vh",
        }}
      >
        <img src={overviewBg} width={"100%"} height={"100%"} />
      </div>

      <Box sx={{ p: 3, width: "100%" }}>
        <AppHeading
          title={`Welcome Back, ${userInfo?.user?.role}!`}
          titleColor={"#F0F0F0"}
          titleFontFamily={"Lato"}
          titleFontWeight={400}
          titleFontSize={30}
          titleLineHeight={"36px"}
          titleLetterSpacing={0.25}
        />

        {/* *************************************************************/}

        <Row gutter={[10, 10]}>
          {/* Card 1 */}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <OverviewCommonCard
              title={"Solar Systems Monitoring"}
              bgColor={"#F0F0F0"}
              titleColor={"#012D75"}
              titleFontSize={30}
              titleFontWeight={400}
              titleFontFamily={"Lato"}
            >
              <Row
                gutter={[10, 10]}
                style={{
                  marginTop: "24px",
                  flex: 1,
                }}
              >
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <OverviewCard2
                      title={"Self Consumption"}
                      value={overview?.solar_consumption?.toLocaleString()}
                      unit={"kWh"}
                    />

                    <OverviewCard2
                      mT={10}
                      title={"Energy Exported"}
                      value={overview?.solar_exported?.toLocaleString()}
                      unit={"kWh"}
                    />

                    <OverviewCard2
                      mT={10}
                      title={"Grid Consumption"}
                      value={overview?.grid_consumption?.toLocaleString()}
                      unit={"kWh"}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <OverviewCard3
                      iconSrc={SolarPanelBackground}
                      value={
                        overview?.live_systems
                          ? overview?.live_systems?.toLocaleString()
                          : null
                      }
                      valFontSize={24}
                      icoWidth={22}
                      secondTitle={"Solar Energy Yield"}
                      secondUnit={"kWh"}
                      secondValue={overview?.total_energy_generation?.toLocaleString()}
                    />
                    <OverviewCard1
                      iconSrc={whiteAlarmIcon}
                      title={"Active Alarms"}
                      value={overview?.active_alarms?.toLocaleString()}
                      valFontSize={24}
                      icoWidth={69}
                      icoHeight={75}
                      valFontWeight={400}
                      mT={10}
                    />
                  </div>
                </Col>
              </Row>
            </OverviewCommonCard>
          </Col>
          {/* Card 1 */}

          {/* Card 2 */}

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={6}
            xl={6}
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <OverviewCommonCard
                title={"Device Monitoring"}
                bgColor={"#F0F0F0"}
                titleColor={"#012D75"}
                titleFontSize={30}
                titleFontWeight={400}
                titleFontFamily={"Lato"}
                titleLineHeight={"36px"}
                titleLetterSpacing={0.25}
              >
                <OverviewCard1
                  iconSrc={installBaseIcon}
                  title={"Installed Base"}
                  value={
                    overview?.install_based
                      ? formatRevenue(overview?.install_based)
                      : null
                  }
                  valUnit={"Kw"}
                  valFontSize={30}
                  icoWidth={80}
                  icoHeight={90}
                  valFontWeight={400}
                  mT={24}
                  con_mT={30}
                  con_mB={20}
                />

                <OverviewCard1
                  iconSrc={liveSystemIcon}
                  title={"Live Systems"}
                  value={
                    overview?.live_systems
                      ? overview?.live_systems?.toLocaleString()
                      : null
                  }
                  icoWidth={80}
                  icoHeight={90}
                  valFontSize={24}
                  valFontWeight={400}
                  mT={10}
                  con_mT={30}
                  con_mB={20}
                />
              </OverviewCommonCard>
            </Col>
          </Col>
          {/* Card 2 */}
          {/* Card 3 */}

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={6}
            xl={6}
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <OverviewCommonCard
                title={"Green Savings"}
                bgColor={"#F0F0F0"}
                titleColor={"#012D75"}
                titleFontSize={30}
                titleFontFamily={"Lato"}
                titleLineHeight={"36px"}
                titleLetterSpacing={0.25}
              >
                <OverviewCard1
                  iconSrc={trees}
                  title={"Trees Planted"}
                  value={formatRevenue(overview?.tree_planted)}
                  valFontSize={24}
                  icoWidth={68}
                  icoHeight={74}
                  mT={24}
                />

                <OverviewCard1
                  iconSrc={co2Blue}
                  title={"Carbon Reduction"}
                  value={formatRevenue(overview?.carbon_saving)}
                  valUnit={"Tonnes"}
                  valFontSize={24}
                  valFontWeight={400}
                  icoWidth={68}
                  icoHeight={74}
                  mT={10}
                />

                <OverviewCard1
                  iconSrc={revenue}
                  title={"Revenue Saving"}
                  value={formatRevenue(overview?.revenue_saving)}
                  valUnit={"PKR"}
                  icoWidth={69}
                  icoHeight={75}
                  valFontSize={24}
                  valFontWeight={400}
                  mT={10}
                />
              </OverviewCommonCard>
            </Col>
          </Col>
          {/* Card 3 */}
        </Row>
        {/* ************************************************************** */}
        <CommonCard
          slabs={overview?.real_health_states}
          title={"Solar Sites Maps"}
          mT={"32px"}
          titleColor={"#454545"}
          titleFontSize={21}
          titleFontWeight={400}
          titleFontFamily={"Lato"}
          titleLineHeight={"36px"}
          titleLetterSpacing={0.25}
          p={"0px"}
          selectedSlab={selectedSlab}
          setSelectedMarker={setSelectedMarker}
          setSelectedSlab={setSelectedSlab}
        >
          <MapComponent
            map_data={overview?.stations}
            selectedSlab={selectedSlab}
          />
        </CommonCard>

        {/* ************************************************************** */}

        <ChartCard
          title={"Normalize Yield (kWh/kW)"}
          mT={32}
          titleColor={"#454545"}
          titleFontSize={21}
          titleFontWeight={400}
          titleFontFamily={"Lato"}
          titleLineHeight={"25.2px"}
        >
          <Formik
            initialValues={PRComparisonFormFields}
            validationSchema={PRComparisonVS}
            onSubmit={(values, { resetForm }) => {}}
          >
            {({
              errors,
              status,
              touched,
              handleSubmit,
              setFieldValue,
              values,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row
                    gutter={[10, 10]}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                      <DevicesDropDown
                        value={values.city}
                        showSearch={true}
                        list={prCitiesList}
                        placeholder={"Cities"}
                        borderColor={"var(--color-wateen)"}
                        onSelect={(item) => {
                          setFieldValue("city", item);
                          setFieldValue("region", "Region");
                          setFieldValue("station_name", "Station");
                          setFieldValue("mount_list", "Mount");
                          setFieldValue("site_breakup_list", "Type");
                          onPrComparison(
                            item,
                            values?.region,
                            values?.station_name,
                            values?.mount_list,
                            values?.site_breakup_list,
                            selectedTimeRange
                          );
                        }}
                      />
                    </Col>

                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                      <DevicesDropDown
                        showSearch={true}
                        list={prRegionsList}
                        placeholder={"Region"}
                        borderColor={"var(--color-wateen)"}
                        value={values.region}
                        onSelect={(item) => {
                          setFieldValue("region", item);
                          setFieldValue("station_name", "Station");
                          setFieldValue("mount_list", "Mount");
                          setFieldValue("site_breakup_list", "Type");

                          onPrComparison(
                            values?.city,
                            item,
                            values?.station_name,
                            values?.mount_list,
                            values?.site_breakup_list,
                            selectedTimeRange
                          );
                        }}
                      />
                    </Col>

                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                      <DevicesDropDown
                        showSearch={true}
                        list={prStationList}
                        value={values.station_name}
                        placeholder={"Sites"}
                        borderColor={"var(--color-wateen)"}
                        onSelect={(item) => {
                          setFieldValue("station_name", item);
                          setFieldValue("mount_list", "Mount");
                          setFieldValue("site_breakup_list", "Type");
                          onPrComparison(
                            values?.city,
                            values?.region,
                            item,
                            values?.mount_list,
                            values?.site_breakup_list,
                            selectedTimeRange
                          );
                        }}
                      />
                    </Col>

                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                      <DevicesDropDown
                        showSearch={true}
                        list={prMountList}
                        value={values.mount_list}
                        placeholder={"Mount"}
                        borderColor={"var(--color-wateen)"}
                        onSelect={(item) => {
                          setFieldValue("mount_list", item);
                          setFieldValue("site_breakup_list", "Type");
                          onPrComparison(
                            values?.city,
                            values?.region,
                            values?.station_name,
                            item,
                            values?.site_breakup_list,
                            selectedTimeRange
                          );
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                      <DevicesDropDown
                        showSearch={true}
                        list={prSiteList}
                        value={values.site_breakup_list}
                        placeholder={"Type"}
                        borderColor={"var(--color-wateen)"}
                        onSelect={(item) => {
                          setFieldValue("site_breakup_list", item);
                          onPrComparison(
                            values?.city,
                            values?.region,
                            values?.station_name,
                            values?.mount_list,
                            item,
                            selectedTimeRange
                          );
                        }}
                      />
                    </Col>
                  </Row>

                  <ChartCard bgColor={"#F0F0F0"}>
                    <Row
                      gutter={[10, 10]}
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                    >
                      <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                        <ChartCard
                          title={"Comparison"}
                          titleColor={"#454545"}
                          titleFontSize={21}
                          titleFontWeight={400}
                          titleFontFamily={"Lato"}
                          titleLineHeight={"25.2px"}
                          bgColor={"#F0F0F0"}
                        ></ChartCard>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={6}
                        xl={6}
                        style={{ marginTop: 20, marginBottom: 10 }}
                      >
                        <Row gutter={[10, 10]}>
                          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <AppButton
                              borderRadius={10}
                              buttonTitle={"Daily"}
                              onClick={() => {
                                onPrComparison(
                                  values?.city,
                                  values?.region,
                                  values?.station_name,
                                  values?.mount_list,
                                  values?.site_breakup_list,
                                  "Daily"
                                );
                                setSelectedTimeRange("Daily");
                              }}
                              backgroundColor={
                                selectedTimeRange === "Daily"
                                  ? "var(--primary_color)"
                                  : "#F0F0F0"
                              }
                              textColor={
                                selectedTimeRange === "Daily"
                                  ? "var(--g4)"
                                  : "var(--neutral_grey)"
                              }
                              width={"100%"}
                              variant={"outlined"}
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <AppButton
                              buttonTitle={"Month"}
                              onClick={() => {
                                onPrComparison(
                                  values?.city,
                                  values?.region,
                                  values?.station_name,
                                  values?.mount_list,
                                  values?.site_breakup_list,
                                  "Month"
                                );
                                setSelectedTimeRange("Month");
                              }}
                              backgroundColor={
                                selectedTimeRange === "Month"
                                  ? "var(--primary_color)"
                                  : "#F0F0F0"
                              }
                              textColor={
                                selectedTimeRange === "Month"
                                  ? "var(--g4)"
                                  : "var(--neutral_grey)"
                              }
                              borderRadius={10}
                              width={"100%"}
                              variant={"outlined"}
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <AppButton
                              buttonTitle={"Year"}
                              onClick={() => {
                                onPrComparison(
                                  values?.city,
                                  values?.region,
                                  values?.station_name,
                                  values?.mount_list,
                                  values?.site_breakup_list,
                                  "Year"
                                );
                                setSelectedTimeRange("Year");
                              }}
                              backgroundColor={
                                selectedTimeRange === "Year"
                                  ? "var(--primary_color)"
                                  : "#F0F0F0"
                              }
                              textColor={
                                selectedTimeRange === "Year"
                                  ? "var(--g4)"
                                  : "var(--neutral_grey)"
                              }
                              borderRadius={10}
                              width={"100%"}
                              variant={"outlined"}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {selectedTimeRange === "Daily" && renderChart(prComparison)}
                    {selectedTimeRange === "Month" && renderChart(prComparison)}
                    {selectedTimeRange === "Year" && renderChart(prComparison)}
                  </ChartCard>
                </Form>
              );
            }}
          </Formik>
        </ChartCard>

        {/* ************************************************************** */}

        {/* <Row gutter={[10, 32]} style={{ marginTop: 32 }}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <CustomTextBox
              text={"Sites Breakup"}
              textFontColor={"#454545"}
              textFontSize={21}
              textFontWeight={400}
              textFontFamily={"Lato"}
              textLineHeight={"25.2px"}
              textLetterSpacing={0}
            ></CustomTextBox>
            <div style={{ margin: "10px 0px 10px 0px" }} />

            <ChartCard bgColor={"#F0F0F0"}>
              <PieCharts
                data={overview?.site_breakup || []}
                chartColors={pieChartColors}
              />
            </ChartCard>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <CustomTextBox
              text={" Mounting Wise - Installation"}
              textFontColor={"#454545"}
              textFontSize={21}
              textFontWeight={400}
              textFontFamily={"Lato"}
              textLineHeight={"25.2px"}
              textLetterSpacing={0}
            ></CustomTextBox>
            <div style={{ margin: "10px 0px 10px 0px" }} />
            <ChartCard
              // mT={20}
              tabs={overview?.mount_wise}
              activeTab={moutningTab?.station_type}
              setActiveTab={(item) => setMountingTab(item)}
              bgColor={"#F0F0F0"}
            >
              <PieCharts
                data={moutningTab?.mounts || []}
                chartColors={pieChartColors}
              />
            </ChartCard>
          </Col>
        </Row> */}
      </Box>
    </AppContainer>
  );
};

export default Overview;
