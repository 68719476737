import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AlarmTable,
  AppContainer,
  AppHeading,
  DevicesDropDown,
} from "../../components";
import {
  deleteAlarmAction,
  filterAlarmsAction,
  getAlarmsAction,
  manipAlarmStatusAction,
  updateAlramStatusAction,
} from "../../redux/actions";
import {
  alarmColumns,
  networkText,
  formatDate,
  oemOptions,
} from "../../utilities";
import { Box } from "@mui/material";
const Alarms = ({ navigation }) => {
  const dispatch = useDispatch(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { alarms, alarmsLoad, sitesDetFetchTime, filteredAlarms } = useSelector(
    (state) => state?.alarms
  );

  const params = new URLSearchParams(location.search);
  const type = location?.state?.type || null;
  const { userInfo } = useSelector((state) => state?.auth);
  const [localAlarmFilter, setLocalAlarmFilter] = useState("All OEM"); // Local state for deviceFilter

  useEffect(() => {
    getData();
  }, []);

  // Get Alarm List
  const getData = async () => {
    if (navigator.onLine) {
      const body = {
        onSuccess: async (res) => {},
        onFailure: (res) => {},
      };

      dispatch(getAlarmsAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const updateStatus = async (alarmId, action) => {
    if (navigator.onLine) {
      const requestBody = {
        alarm_id: alarmId,
        action: action,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
            dispatch(
              manipAlarmStatusAction({
                id: res?.data?.alarm_id,
                status: res?.data?.action,
              })
            );
          }
        },
        onFailure: (error) => {},
      };

      dispatch(updateAlramStatusAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const deleteAlarm = async (alarmId) => {
    if (navigator.onLine) {
      const requestBody = {
        alarm_id: alarmId,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
            toast.success("Alarm deleted successfully!!");
          }
        },
        onFailure: (error) => {},
      };

      dispatch(deleteAlarmAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const handleRefreshClick = () => {
    window.location.reload();
  };

  const handleFilterChange = (item) => {
    setLocalAlarmFilter(item);
    dispatch(filterAlarmsAction(item));
  };

  return (
    <AppContainer loading={alarmsLoad}>
      <Box sx={{ p: 3, width: "100%" }}>
        <Col className="alarmCon">
          <AppHeading
            title={"Alarms"}
            onRefreshClick={handleRefreshClick}
            refreshText={formatDate(sitesDetFetchTime)}
            showButton={
              [
                "Super Admin",
                "Admin",
                "Site Manager",
                "OPS Manager",
                "Client",
              ].includes(userInfo?.user?.role) || false
            }
            buttonTitle={"Add Alarm"}
            onClick={() => {
              navigate("/add-alarm");
            }}
            titleFontFamily={"Lato"}
            titleFontWeight={400}
            titleFontSize={30}
            titleLineHeight={"36px"}
            titleLetterSpacing={0.25}
            titleColor={"var(--neutral_grey)"}
          />

          <Col xs={10} sm={10} md={3} lg={3} xl={3}>
            <DevicesDropDown
              value={localAlarmFilter}
              list={oemOptions}
              borderColor={"rgba(224, 224, 224, 1)"}
              onSelect={handleFilterChange}
            />
          </Col>

          {filteredAlarms && (
            <AlarmTable
              search_key={type == "all" ? null : type}
              rows={filteredAlarms}
              columns={alarmColumns}
              handleAction={(alarmId, action) => updateStatus(alarmId, action)}
              handleDelete={(alarmId) => deleteAlarm(alarmId)}
              showActions={
                [
                  "Super Admin",
                  "Admin",
                  "Site Manager",
                  "OPS Manager",
                  "Client",
                ].includes(userInfo?.user?.role) || false
              }
            />
          )}
        </Col>
      </Box>
    </AppContainer>
  );
};

export default Alarms;
