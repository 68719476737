import { ErrorMessage, Field } from "formik";
import { Input } from "antd";
import React from "react";

const { TextArea } = Input;

export const InputBox = ({ label, vldName, mT, placeholder }) => {
  const inputStyles = {
    border: "none",
    boxShadow: "0 7px 15px var(--gr10)",
  };

  return (
    <div style={{ marginTop: mT }} className="appInputCon">
      <div className={`form-group mt-1`}>
        <label className="labelText">{label}</label>

        <Field
          as={TextArea} // Pass TextArea as the component prop of Field
          rows={4} // Adjust the number of rows as needed
          className="form-control mt-1"
          placeholder={placeholder}
          name={vldName}
          style={inputStyles}
        />
        <ErrorMessage name={vldName} component="div" className="error" />
      </div>
    </div>
  );
};
