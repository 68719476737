import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import * as React from "react";
import { useState } from "react";
import { roles } from "../../utilities/constant";

const CustomPaper = styled(Paper)({
  borderRadius: 10,
  marginTop: 20,
  backgroundColor: "var(--neutral_light)",
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton style={{ color: "var(--primary_color)" }} />
      <GridToolbarFilterButton style={{ color: "var(--primary_color)" }} />
      <GridToolbarDensitySelector style={{ color: "var(--primary_color)" }} />
      <GridToolbarExport style={{ color: "var(--primary_color)" }} />
    </GridToolbarContainer>
  );
}

export function InductionTable({
  rows = [],
  columns = [],
  handleRowClick = () => {},
  handleAction = () => {},
  search_key = "",
  editData = () => {},
  downloadData = () => {},
  role,
}) {
  const [filterText, setFilterText] = useState("");
  const [openRowId, setOpenRowId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  React.useEffect(() => {
    if (search_key) {
      setFilterText(search_key);
    }
  }, [search_key]);

  const filteredRows = rows.filter((row) => {
    return (
      row.site_name?.toLowerCase().includes(filterText?.toLowerCase()) ||
      row.client_name?.toLowerCase().includes(filterText?.toLowerCase()) ||
      row.status?.toLowerCase().includes(filterText?.toLowerCase()) ||
      row.submitted_by?.toLowerCase().includes(filterText?.toLowerCase()) ||
      row.created_at?.toLowerCase().includes(filterText?.toLowerCase())
    );
  });

  const renderColumns = columns.map((column) => {
    let cellClassName;

    if (column.id === "priority") {
      cellClassName = (params) =>
        params.value === "Critical" ? "highPriorityCell" : "normalPriorityCell";
    }
    if (column.id === "actions") {
      return {
        field: column.id,
        headerName: column.label,
        flex: 1,
        headerClassName: "tableHeadCell",
        headerAlign: column.align,
        align: column.align,
        minWidth: column.minWidth,
        renderCell: (params) => (
          <React.Fragment>
            <MoreVertIcon
              style={{ cursor: "pointer" }}
              onClick={(event) => handleIconClick(event, params.row.id)}
            />

            {openRowId === params.row.id && (
              <Menu
                anchorEl={anchorEl}
                open={openRowId === params.row.id}
                onClose={() => setOpenRowId(null)}
                PaperProps={{
                  sx: {
                    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                {role === roles.OPS_MANAGER ||
                role === roles.ADMIN ||
                role === roles.SUPER_ADMIN ? (
                  <div>
                    <MenuItem
                      onClick={() =>
                        handleActionClick(params.row?.id, "Approved")
                      }
                      disabled={
                        params?.row?.status === "Approved" ? true : false
                      }
                    >
                      Approve
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        handleActionClick(params.row?.id, "Rejected")
                      }
                      disabled={
                        params?.row?.status === "Approved" ? true : false
                      }
                    >
                      Reject
                    </MenuItem>
                  </div>
                ) : null}
                {role === roles.ADMIN ||
                role === roles.SUPER_ADMIN ||
                role === roles.INSTALLER ? (
                  <MenuItem
                    onClick={() => editData(params?.row)}
                    disabled={
                      params?.row?.status === "Approved" &&
                      role != roles.SUPER_ADMIN
                        ? true
                        : false
                    }
                  >
                    Edit
                  </MenuItem>
                ) : null}
                <MenuItem onClick={() => downloadData(params?.row)}>
                  Download
                </MenuItem>
                :
              </Menu>
            )}
          </React.Fragment>
        ),
      };
    }

    if (column.id === "created_at") {
      return {
        field: column.id,
        headerName: column.label,
        flex: 1,
        headerClassName: "tableHeadCell",
        headerAlign: column.align,
        align: column.align,
        minWidth: column.minWidth,
        valueGetter: (params) =>
          moment(params.row.created_at).format("YYYY-MM-DD HH:mm:ss"),
      };
    }

    return {
      field: column.id,
      headerName: column.label,
      flex: 1,
      headerClassName: "tableHeadCell",
      headerAlign: column.align,
      align: column.align,
      minWidth: column.minWidth,
      cellClassName,
    };
  });

  const handleActionClick = (id, action) => {
    handleAction(id, action);
    setOpenRowId(null);
  };

  const handleIconClick = (event, rowId) => {
    event.stopPropagation();
    setOpenRowId(rowId);
    setAnchorEl(event.currentTarget);
  };

  return (
    <React.Fragment>
      <CustomPaper
        className={"paperCon"}
        sx={{
          width: "98%",
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={renderColumns}
          slots={{
            toolbar: CustomToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          sx={{
            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
              {
                marginTop: "1em",
                marginBottom: "1em",
              },

            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          pageSizeOptions={[5, 10, 20]}
          onRowClick={(params) => {
            handleRowClick(params.row);
          }}
        />
      </CustomPaper>
    </React.Fragment>
  );
}
