import { DatePicker } from "antd";
import { ErrorMessage } from "formik";
import React from "react";
import { displayDateFormats } from "../../utilities";

export const DateIntervalInput = ({
  label,
  value,
  defaultValue,
  vldName,
  mT,
  placeholder,
  onSelect,
  formatType = "daily", // Add this prop to specify the format type (e.g., 'day', 'month', 'year')
}) => {
  const { RangePicker } = DatePicker;

  let picker;
  let dateFormat;
  switch (formatType) {
    case "daily":
      picker = "day";
      dateFormat = displayDateFormats.date;
      break;
    case "monthly":
      picker = "month";
      dateFormat = displayDateFormats.month;
      break;
    case "yearly":
      picker = "year";
      dateFormat = displayDateFormats.year;
      break;
    default:
      picker = "day";
      dateFormat = displayDateFormats.date;
  }

  const dateInputStyles = {
    boxShadow: "0 7px 15px var(--gr10)",
    width: "100%",
    marginTop: "5px",
    borderRadius: "5px",
    borderColor: "transparent",
    height: "42px" || "auto",
  };
  return (
    <div className="appInputCon">
      <div className={`form-group  mt-1`}>
        <label className="labelText">{label}</label>
        <RangePicker
          onChange={(date, dateStrings) => {
            onSelect(date);
          }}
          style={dateInputStyles}
          size={"large"}
          value={value}
          defaultValue={defaultValue}
          // bordered={false}
          picker={picker}
          format={dateFormat}
        />
        <ErrorMessage name={vldName} component="div" className="error" />
      </div>
    </div>
  );
};
