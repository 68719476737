import { Col, Row } from "antd";
import { Form, Formik } from "formik";
import React from "react";

import { AppInput, CommonCard } from "../../../components";
import { Induction2FormFields, InductionStep2VS } from "../../../utilities";
import { FilterDropDown } from "../../../components";

export const Step2Comp = ({ onClickNext, stepper, setStepper, data, supp }) => {
  return (
    <Formik
      initialValues={Induction2FormFields(data)}
      validationSchema={InductionStep2VS}
      onSubmit={(values) => {
        onClickNext(values);
        setStepper(stepper + 1);
      }}
    >
      {({ errors, status, touched, handleSubmit, setFieldValue, values }) => {
        return (
          <Form className="Auth-form" onSubmit={handleSubmit}>
            <CommonCard
              title={"Please fill in the details"}
              mT={50}
              buttonTitle={"Next"}
              secButtonTitle={"Previous"}
              secOnClick={() => {
                setStepper(stepper - 1);
              }}
              onClick={handleSubmit}
            >
              <Row gutter={[50, 50]} style={{ marginTop: 50 }}>
                {/* Col 1 */}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <AppInput
                    label={"Inverter Supplier Name"}
                    vldName={"invrSupplName"}
                    placeholder={"Enter Inverter Supplier Name"}
                  />
                  <AppInput
                    label={"Mechnaical Contractor Name"}
                    vldName={"mechContrName"}
                    placeholder={"Enter Mechnaical Contractor Name"}
                  />

                  <AppInput
                    label={"Electrical Contractor Name"}
                    vldName={"electContrName"}
                    placeholder={"Enter Electrical Contractor Name"}
                  />

                  {/* <AppInput
                    label={"Grid Supplier Name"}
                    vldName={"gridSupplName"}
                    placeholder={"Enter Grid Supplier Name"}
                  /> */}

                  <FilterDropDown
                    label={"Grid Supplier Name"}
                    value={values?.gridSupplName || null}
                    placeholder={"Enter Grid Supplier Name"}
                    vldName={"gridSupplName"}
                    list={supp || []}
                    onSelect={(item) => {
                      setFieldValue("gridSupplName", item);
                    }}
                  />
                </Col>

                {/* Col 2 */}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <AppInput
                    label={"Reference Number of electricity bill"}
                    vldName={"refElectBill"}
                    placeholder={"Enter Reference Number  of electricity bill"}
                  />
                  <AppInput
                    label={"POC"}
                    vldName={"poc"}
                    placeholder={"Enter POC"}
                  />
                  <AppInput
                    label={"POC Number"}
                    vldName={"pocNo"}
                    placeholder={"Enter POC Number"}
                    fieldType={"number"}
                  />
                </Col>
              </Row>
            </CommonCard>
          </Form>
        );
      }}
    </Formik>
  );
};
