import { Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContainer, AppHeading, InductionTable } from "../../../components";
import {
  getAllFormsAction,
  updateFormStatusAction,
} from "../../../redux/actions";
import { inductionFormColumns, networkText } from "../../../utilities";

import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import moment from "moment";
import Box from "@mui/material/Box";

const FormList = ({ navigation }) => {
  const dispatch = useDispatch(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { inductions_forms, formListloader } = useSelector(
    (state) => state?.inductions
  );

  const { userInfo } = useSelector((state) => state?.auth);

  const type = location?.state?.type || null;

  useEffect(() => {
    getData();
  }, []);

  // Get Alarm List
  const getData = async () => {
    if (navigator.onLine) {
      const body = {
        onSuccess: async (res) => {},
        onFailure: (res) => {},
      };

      dispatch(getAllFormsAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const updateStatus = async (id, action) => {
    if (navigator.onLine) {
      const requestBody = {
        id: id,
        action: action,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {},
        onFailure: (error) => {},
      };
      dispatch(updateFormStatusAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const downloadData = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const mainWorksheet = workbook.addWorksheet("Site Details");

    // Style for header (A1 cell)
    mainWorksheet.getCell("A1").font = {
      bold: true,
      size: 20,
      color: { argb: "FFFFFF" },
    };
    mainWorksheet.getCell("A1").alignment = { horizontal: "center" };

    const columnA = mainWorksheet.getColumn("A");
    columnA.width = 40;

    const columnB = mainWorksheet.getColumn("B");
    columnB.width = 40;

    // Style for rows
    const defaultRowStyle = {
      alignment: { horizontal: "center" },
      font: { bold: false, size: 14 },
    };

    const rows = [
      ["ID", data?.id],
      ["Site Id", data?.site_id],
      ["Client Name", data?.client_name],
      ["Site Name", data?.site_name],
      ["Postal Address", data?.postal_address],
      ["City", data?.city],
      ["Region", data?.region],
      ["Longitude", data?.longitude],
      ["Latitude", data?.latitude],
      ["Site Type", data?.site_type],
      ["System Type", data?.system_type],
      ["Site Category", data?.site_category],
      ["Export Type", data?.is_export_enabled],
      ["System Size", data?.system_size],
      [
        "Site Commissioning Date",
        data?.site_comissioning_date
          ? moment(data?.site_comissioning_date).format("DD-MMM-YYYY")
          : "",
      ],
      [
        "Inverter Commissioning Date",
        data?.inverter_commisioning_date
          ? moment(data?.inverter_commisioning_date).format("DD-MMM-YYYY")
          : "",
      ],
      ["Structure Type", data?.structure_type],
      ["Mount Type", data?.mount_type],
      [
        "Warranty Starts",
        data?.warranty_starts
          ? moment(data?.warranty_starts).format("DD-MMM-YYYY")
          : "",
      ],
      ["OEM Panel", data?.oem_panel],
      ["Model Name", data?.model_name],
      ["Panels Type", data?.panels_type],
      ["Panel Kind", data?.panel_kind],
      ["P_max", data?.p_max],
      ["Module Efficiency STC", data?.module_efficienncy_stc],
      ["Maximum System Voltage", data?.maximum_system_voltage],
      ["Temperature Coefficients Pmax", data?.temperature_cofficients_pmax],
      ["Total Panels", data?.total_panels],
      ["Inverter Model", data?.inverter_model],
      ["Total Inverters", data?.total_inverters],
      ["Inverter Datasheet", data?.inverter_datasheet],
      ["Inverter Supplier Name", data?.inverter_supplier_name],
      ["Mechanical Contractor Name", data?.mechnaical_contractor_name],
      ["Electrical Contractor Name", data?.electrical_contractor_name],
      ["String MPPT", data?.string_mppt],
      ["Grid Supplier Name", data?.grid_supplier_name],
      [
        "Reference Number Electricity Bill",
        data?.reference_number_electricity_bill,
      ],
      ["POC", data?.poc],
      ["POC Number", data?.poc_number],
      // ["Status", data?.status],
      // ["Created At", data?.created_at],
      ["Submitted By", data?.submitted_by],
    ];

    rows.forEach((row) => {
      const newRow = mainWorksheet.addRow(row);
      newRow.eachCell((cell) => {
        cell.style = defaultRowStyle;
      });
    });

    const mppt_json =
      data?.mppt_json && typeof data?.mppt_json === "string"
        ? JSON.parse(data?.mppt_json)
        : [];

    if (Array.isArray(mppt_json) && mppt_json.length > 0) {
      const mpptWorksheet = workbook.addWorksheet("MPPT");
      mpptWorksheet.getCell("A1").font = { bold: true, size: 14 };
      mpptWorksheet.getCell("A1").alignment = { horizontal: "center" };

      const mpptRows = [
        [
          "MPPT",
          "Input",
          "No of Panels",
          "Mount Type",
          "Orientation",
          "Tilt",
          "Voc",
          "Vmp",
          "Imp",
        ],
        ...mppt_json.map((item) => [
          item?.mppt,
          item?.input,
          item?.no_of_panels,
          item?.mount_type,
          item?.orientation,
          item?.tilt,
          item?.voc,
          item?.vmp,
          item?.imp,
        ]),
      ];

      mpptRows.forEach((row, index) => {
        const newRow = mpptWorksheet.addRow(row);
        newRow.eachCell((cell) => {
          cell.alignment = defaultRowStyle.alignment;
          cell.font = 12;

          if (index === 0) {
            cell.font = { bold: true, size: 14 };
          }
        });
      });

      mpptWorksheet.columns.forEach((column) => {
        column.width = 20;
      });
    }

    const buffer = await workbook.xlsx.writeBuffer();
    saveBufferAsExcelFile(buffer, "induction-form.xlsx");
  };

  const saveBufferAsExcelFile = (buffer, fileName) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, fileName);
  };
  const handleRowClick = (data) => {
    navigate(`/view-induction/${data.id}`, {
      state: { data: data, onbId: data?.id },
    });
  };

  return (
    <AppContainer loading={formListloader}>
      <Box sx={{ p: 3, width: "100%" }}>
        <Col className="alarmCon">
          <AppHeading
            title={"Induction Form"}
            showButton={true}
            buttonTitle={"Add Form"}
            onClick={() => {
              navigate("/add-form");
            }}
          />
          {inductions_forms && (
            <InductionTable
              handleRowClick={(item) => handleRowClick(item)}
              search_key={type == "all" ? null : type}
              rows={inductions_forms}
              columns={inductionFormColumns}
              handleAction={(id, action) => updateStatus(id, action)}
              editData={(data) => {
                navigate("/add-form", { state: { onbId: data?.id } });
              }}
              downloadData={(data) => {
                downloadData(data);
              }}
              role={userInfo.user.role}
            />
          )}
        </Col>
      </Box>
    </AppContainer>
  );
};
export default FormList;
