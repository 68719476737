import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AppContainer,
  AppHeading,
  DevicesDropDown,
  SiteTable,
} from "../../../components";
import {
  networkText,
  siteColumns,
  formatDate,
  oemOptions,
} from "../../../utilities";
import {
  filterSitesAction,
  getAllSitesAction,
  // updateSiteExportAction,
  // manipSiteExportAction,
} from "../../../redux/actions";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";

const Sites = ({}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sites, siteload, sitesFetchTime, filteredSites } = useSelector(
    (state) => state?.sites
  );

  const params = new URLSearchParams(location.search);
  const type = location?.state?.type || null;
  const { userInfo } = useSelector((state) => state?.auth);

  const [localSiteFilter, setLocalSiteFilter] = useState("All OEM"); // Local state for deviceFilter

  useEffect(() => {
    getAllSites();
  }, []);

  const getAllSites = () => {
    if (navigator.onLine) {
      const requestBody = {
        values: null,
        onSuccess: (res) => {},
        onFailure: () => {},
      };
      dispatch(getAllSitesAction(requestBody));
    } else {
      toast.error(networkText);
    }
  };

  const handleRowClick = (data) => {
    navigate("/site-details", { state: { data } });
  };

  const handleRefreshClick = () => {
    window.location.reload();
  };

  const handleFilterChange = (item) => {
    setLocalSiteFilter(item);
    dispatch(filterSitesAction(item));
  };
  return (
    <AppContainer loading={siteload}>
      <Box sx={{ p: 3, width: "99%" }}>
        <Col className="sitesCon">
          <AppHeading
            title={"Sites"}
            onRefreshClick={handleRefreshClick}
            refreshText={formatDate(sitesFetchTime)}
            titleFontFamily={"Lato"}
            titleFontWeight={400}
            titleFontSize={30}
            titleLineHeight={"36px"}
            titleLetterSpacing={0.25}
            titleColor={"var(--neutral_grey)"}
          />

          <Col xs={10} sm={10} md={3} lg={3} xl={3}>
            <DevicesDropDown
              value={localSiteFilter}
              list={oemOptions}
              borderColor={"rgba(224, 224, 224, 1)"}
              onSelect={handleFilterChange}
            />
          </Col>
          {sites && (
            <SiteTable
              handleRowClick={(item) => handleRowClick(item)}
              rows={filteredSites}
              columns={siteColumns}
              // handleAction={(siteId, actions) => updateStatus(siteId, actions)}
              search_key={type === "all" ? null : type}
              showActions={
                [
                  "Super Admin",
                  "Admin",
                  "Site Manager",
                  "OPS Manager",
                  "Client",
                ].includes(userInfo?.user?.role) || false
              }
            />
          )}
        </Col>
      </Box>
    </AppContainer>
  );
};

export default Sites;
