import { Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContainer, AppHeading, TicketTable } from "../../components";
import { getTicketsAction } from "../../redux/actions";
import { networkText, rowstick, ticketColumns } from "../../utilities";
import { Box } from "@mui/material";
const Tickets = ({ navigation }) => {
  const dispatch = useDispatch(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { tickets, ticketsLoad } = useSelector((state) => state?.alarms);
  const type = location?.state?.type || null;

  useEffect(() => {
    getData();
  }, []);

  // Get Alarm List
  const getData = async () => {
    if (navigator.onLine) {
      const body = {
        onSuccess: async (res) => {},
        onFailure: (res) => {},
      };

      dispatch(getTicketsAction(body));
    } else {
      toast.error(networkText);
    }
  };

  return (
    <AppContainer loading={ticketsLoad}>
      <Box sx={{ p: 3, width: "100%" }}>
        <Col className="alarmCon">
          <AppHeading title={"Tickets"} />
          {tickets && (
            <TicketTable
              search_key={type}
              rows={tickets}
              columns={ticketColumns}
            />
          )}
        </Col>
      </Box>
    </AppContainer>
  );
};

export default Tickets;
