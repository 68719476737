import { date } from "yup";
import { Battery, Generator, Grid, Home, SolarPanel } from "./images";

export const sideBarItems = [
  {
    id: 0,
    title: "Overview",
    icon: "home",
    path: "/",
    roles: ["Super Admin", "Admin", "Site Manager", "Client", "OPS Manager"],
  },
  {
    id: 1,
    title: "O & M",
    icon: "om",
    path: "/operations",
    sub_path: "/tickets",
    roles: ["Super Admin", "Admin", "Site Manager", "OPS Manager"],
  },
  {
    id: 3,
    title: "Sites",
    icon: "sites",
    path: "/sites",
    sub_path: "/site-details",
    roles: ["Super Admin", "Admin", "Site Manager", "Client", "OPS Manager"],
  },
  {
    id: 2,
    title: "Devices",
    icon: "devices",
    path: "/devices",
    sub_path: "/inverter-details",
    sub_path1: "/device-details",
    roles: ["Super Admin", "Admin", "Site Manager", "Client", "OPS Manager"],
  },
  {
    id: 4,
    title: "Alarms",
    icon: "alarms",
    path: "/alarms",
    sub_path: "/add-alarm",
    roles: ["Super Admin", "Admin", "Site Manager", "OPS Manager", "Client"],
  },
  {
    id: 5,
    title: "Induction",
    icon: "induction",
    path: "/induction",
    sub_path: "/add-form",
    sub_path1: "/view-induction/:id",
    roles: ["Super Admin", "Admin", "Installer", "OPS Manager"],
  },
  {
    id: 6,
    title: "Users",
    icon: "user",
    path: "/user",
    sub_path: "/add-user",
    sub_path1: "/update-user",
    roles: ["Super Admin"],
  },
  {
    id: 7,
    title: "Reports",
    icon: "reports",
    path: "/reports",
    roles: ["Super Admin", "Admin", "Site Manager", "Client", "OPS Manager"],
  },
  {
    id: 8,
    title: "Contact Us",
    icon: "contact",
    path: "/contact-us",
    roles: ["Super Admin", "Admin", "Site Manager", "Client", "OPS Manager"],
  },
  {
    id: 9,
    title: "Settings",
    icon: "settings",
    path: "/settings",
    roles: ["Super Admin", "Admin"],
  },
  {
    id: 10,
    title: "Vendor",
    icon: "vendor",
    path: "/vendor",
    roles: ["Super Admin"],
  },
];

//Overview Scr Data
export const overviewList = {
  totalGeneration: "123,040",
  totalInstallPlant: "25",
  carbonSaving: "697,07",
  activeAlarm: "25",
};

export const mountingWiseTabs = [
  {
    title: "Industrial",
  },
  {
    title: "Commercial",
  },
  {
    title: "Residential",
  },
];

export const mountingWiseInst = [
  { value: 2, name: "Roof Mount" },
  { value: 3, name: "Shed Mount" },
  { value: 5, name: "Ground Mount" },
];

export const sitesBreakup = [
  { name: "Residential Sites", value: 4 },
  { name: "Commercial Sites", value: 1 },
  { name: "Industrial Sites", value: 4 },
];

export const brandBreakdown = [
  { name: "Huawei", value: 20 },
  { name: "Sungrow", value: 80 },
];

export const pieChartColors = ["#020246", "#000075", "#3944bc", "#38acec"];

//Operations Scr Data
export const operationsList = {
  allSites: "25",
  platinumSites: "5",
  goldSites: "10",
  silverSites: "10",
};

export const alarmsList = {
  allAlarms: "25",
  criticalAlarms: "5",
  majorAlarms: "10",
  minorAlarms: "10",
};

export const siteWiseAlarms = [
  { site: "Ameen Farm House", major: 10, minor: 10, critical: 5 },
  { site: "TRTM FSD", major: 0, minor: 0, critical: 10 },
  { site: "Pepsi Co Greenhouse", major: 10, minor: 0, critical: 0 },
  { site: "Syngenta Warehouse", major: 10, minor: 0, critical: 0 },
];

//Site Details Scr Data
export const siteDetails = {
  todayRevenue: "0",
  cuf: "10",
  todayYield: "0",
  activeFault: "0",
  sysSize: "100",
  noOfInverter: "1",
  perfRatio: "0",
  tcpr: "0",
};
export const solarAdvangtage = {
  co2Reduction: "123,040",
  oilSaved: "79",
  treesPlanted: "1,714",
};

export const inverterDetails = {
  id: "123456",
  startTime: "00:00:00",
  name: "HWE-05-2323",
  shutdownTime: "00:00:00",
  size: "100kW",
  downTime: "0 hrs, 0 mins",
  comissionDate: "2022-09-14",
  supplier: "Moiz",
  warranty: "2.71 Yrs",
};

export const powerMix = [
  { time: "00:00:00", solar: 2.75, grid: 7.63 },
  { time: "00:35:00", solar: 2.64, grid: 7.79 },
  { time: "01:10:00", solar: 2.41, grid: 8.12 },
  { time: "01:45:00", solar: 1.95, grid: 8.58 },
  { time: "02:20:00", solar: 1.39, grid: 9.15 },
  { time: "02:55:00", solar: 1.04, grid: 9.5 },
  { time: "03:30:00", solar: 1.12, grid: 9.42 },
  { time: "04:05:00", solar: 1.47, grid: 9.07 },
  { time: "04:40:00", solar: 1.95, grid: 8.59 },
  { time: "05:15:00", solar: 2.46, grid: 8.08 },
  { time: "05:50:00", solar: 2.84, grid: 7.7 },
  { time: "06:25:00", solar: 3.05, grid: 7.49 },
  { time: "05:15:00", solar: 2.46, grid: 8.08 },
  { time: "07:00:00", solar: 3.11, grid: 7.43 },
  { time: "07:35:00", solar: 3.03, grid: 7.51 },
  { time: "08:10:00", solar: 2.8, grid: 7.74 },
  { time: "08:45:00", solar: 2.46, grid: 8.08 },
  { time: "09:20:00", solar: 2.08, grid: 8.46 },
  { time: "09:55:00", solar: 1.68, grid: 8.86 },
  { time: "10:30:00", solar: 1.34, grid: 9.2 },
  { time: "11:05:00", solar: 1.12, grid: 9.42 },
  { time: "11:40:00", solar: 1.04, grid: 9.5 },
  { time: "12:15:00", solar: 1.12, grid: 9.42 },
  { time: "12:50:00", solar: 1.34, grid: 9.2 },
  { time: "13:25:00", solar: 1.68, grid: 8.86 },
  { time: "14:00:00", solar: 2.08, grid: 8.46 },
  { time: "14:35:00", solar: 2.46, grid: 8.08 },
  { time: "15:10:00", solar: 2.8, grid: 7.74 },
  { time: "15:45:00", solar: 3.03, grid: 7.51 },
  { time: "16:20:00", solar: 3.11, grid: 7.43 },
  { time: "16:55:00", solar: 3.05, grid: 7.49 },
  { time: "17:30:00", solar: 2.84, grid: 7.7 },
  { time: "18:05:00", solar: 2.46, grid: 8.08 },
  { time: "18:40:00", solar: 1.95, grid: 8.59 },
  { time: "19:15:00", solar: 1.47, grid: 9.07 },
  { time: "19:50:00", solar: 1.12, grid: 9.42 },
  { time: "20:25:00", solar: 1.04, grid: 9.5 },
  { time: "21:00:00", solar: 1.12, grid: 9.42 },
  { time: "21:35:00", solar: 1.34, grid: 9.2 },
  { time: "22:10:00", solar: 1.68, grid: 8.86 },
  { time: "22:45:00", solar: 2.08, grid: 8.46 },
  { time: "23:20:00", solar: 2.46, grid: 8.08 },
];
export const inverPerformace = [
  { time: "00:00:00", inverter: 5.2 },
  { time: "00:35:00", inverter: 6.8 },
  { time: "01:10:00", inverter: 4.3 },
  { time: "01:45:00", inverter: 7.1 },
  { time: "02:20:00", inverter: 3.9 },
  { time: "02:55:00", inverter: 8.2 },
  { time: "03:30:00", inverter: 5.7 },
  { time: "04:05:00", inverter: 6.4 },
  { time: "04:40:00", inverter: 4.9 },
  { time: "05:15:00", inverter: 7.6 },
  { time: "05:50:00", inverter: 3.3 },
  { time: "06:25:00", inverter: 8.8 },
  { time: "05:15:00", inverter: 5.5 },
  { time: "07:00:00", inverter: 4.1 },
  { time: "07:35:00", inverter: 7.9 },
  { time: "08:10:00", inverter: 6.2 },
  { time: "08:45:00", inverter: 3.8 },
  { time: "09:20:00", inverter: 8.1 },
  { time: "09:55:00", inverter: 4.7 },
  { time: "10:30:00", inverter: 7.4 },
  { time: "11:05:00", inverter: 5.9 },
  { time: "11:40:00", inverter: 6.7 },
  { time: "12:15:00", inverter: 4.2 },
  { time: "12:50:00", inverter: 7.3 },
  { time: "13:25:00", inverter: 5.1 },
  { time: "14:00:00", inverter: 6.5 },
  { time: "14:35:00", inverter: 4.6 },
  { time: "15:10:00", inverter: 7.8 },
  { time: "15:45:00", inverter: 3.7 },
  { time: "16:20:00", inverter: 8.3 },
  { time: "16:55:00", inverter: 5.4 },
  { time: "17:30:00", inverter: 6.9 },
  { time: "18:05:00", inverter: 4.4 },
  { time: "18:40:00", inverter: 7.2 },
  { time: "19:15:00", inverter: 5.3 },
  { time: "19:50:00", inverter: 6.6 },
  { time: "20:25:00", inverter: 4.5 },
  { time: "21:00:00", inverter: 7.7 },
  { time: "21:35:00", inverter: 3.6 },
  { time: "22:10:00", inverter: 8.5 },
  { time: "22:45:00", inverter: 5.8 },
  { time: "23:20:00", inverter: 6.3 },
];

export const perfRatioHeatmap = [
  {
    name: "MPPT-1",
    data: [
      {
        x: "Inverter1",
        y: 347,
      },
    ],
  },
  {
    name: "MPPT-2",
    data: [
      {
        x: "Inverter1",
        y: 332,
      },
    ],
  },
  {
    name: "MPPT-3",
    data: [
      {
        x: "Inverter1",
        y: 357,
      },
    ],
  },
  {
    name: "MPPT-4",
    data: [
      {
        x: "Inverter1",
        y: 89,
      },
    ],
  },
  {
    name: "MPPT-5",
    data: [
      {
        x: "Inverter1",
        y: 53,
      },
    ],
  },
  {
    name: "MPPT-5",
    data: [
      {
        x: "Inverter1",
        y: 79,
      },
    ],
  },
  {
    name: "MPPT-7",
    data: [
      {
        x: "Inverter1",
        y: 10,
      },
    ],
  },
  {
    name: "MPPT-8",
    data: [
      {
        x: "Inverter1",
        y: 10,
      },
    ],
  },
  {
    name: "MPPT-9",
    data: [
      {
        x: "Inverter1",
        y: 10,
      },
    ],
  },
  {
    name: "MPPT-10",
    data: [
      {
        x: "Inverter1",
        y: 35,
      },
    ],
  },
];

//Equipment Details Scr Data
export const technicalSpecs = {
  currentPower: "0",
  inverterEff: "0",
  totalCurt: "0",
  totalFault: "0",
  inverterTemp: "0.0",
  mmptMismatch: "11 hrs, 45 mins",
  gridFreq: "0.0",
};

export const energyLogger = {
  netEnergy: 3056,
  energyOff: 264,
  energyPeak: 628,
  reactiveEnergyOff: 877,
  rectiveEnergy: 179,
};

export const loadshedding = [75];

export const imbalanceTabs = [
  {
    title: "Phase Voltage Imbalance",
  },
  {
    title: "Frequency Imbalance",
  },
];

export const voltageImbalance = [
  { time: "00:00:00", imbalance: 0.193 },
  { time: "00:35:00", imbalance: 0.278 },
  { time: "01:10:00", imbalance: 0.125 },
  { time: "01:45:00", imbalance: 0.407 },
  { time: "02:20:00", imbalance: 0.091 },
  { time: "02:55:00", imbalance: 0.319 },
  { time: "03:30:00", imbalance: 0.255 },
  { time: "04:05:00", imbalance: 0.146 },
  { time: "04:40:00", imbalance: 0.389 },
  { time: "05:15:00", imbalance: 0.235 },
  { time: "05:50:00", imbalance: 0.432 },
  { time: "06:25:00", imbalance: 0.381 },
  { time: "05:15:00", imbalance: 0.297 },
  { time: "07:00:00", imbalance: 0.273 },
  { time: "07:35:00", imbalance: 0.476 },
  { time: "08:10:00", imbalance: 0.098 },
  { time: "08:45:00", imbalance: 0.175 },
  { time: "09:20:00", imbalance: 0.426 },
  { time: "09:55:00", imbalance: 0.348 },
  { time: "10:30:00", imbalance: 0.058 },
  { time: "11:05:00", imbalance: 0.444 },
  { time: "11:40:00", imbalance: 0.201 },
  { time: "12:15:00", imbalance: 0.112 },
  { time: "12:50:00", imbalance: 0.482 },
  { time: "13:25:00", imbalance: 0.327 },
  { time: "14:00:00", imbalance: 0.183 },
  { time: "14:35:00", imbalance: 0.346 },
  { time: "15:10:00", imbalance: 0.245 },
  { time: "15:45:00", imbalance: 0.074 },
  { time: "16:20:00", imbalance: 0.403 },
  { time: "16:55:00", imbalance: 0.212 },
  { time: "17:30:00", imbalance: 0.488 },
  { time: "18:05:00", imbalance: 0.159 },
  { time: "18:40:00", imbalance: 0.091 },
  { time: "19:15:00", imbalance: 0.325 },
  { time: "19:50:00", imbalance: 0.294 },
  { time: "20:25:00", imbalance: 0.423 },
  { time: "21:00:00", imbalance: 0.076 },
  { time: "21:35:00", imbalance: 0.176 },
  { time: "22:10:00", imbalance: 0.401 },
  { time: "22:45:00", imbalance: 0.214 },
  { time: "23:20:00", imbalance: 0.143 },
];

export const activePower = [
  { time: "00:00:00", phaseA: 18.123, phaseB: 9.527, phaseC: 22.986 },
  { time: "00:35:00", phaseA: 2.752, phaseB: 12.421, phaseC: 5.697 },
  { time: "01:10:00", phaseA: 9.314, phaseB: 20.765, phaseC: 6.898 },
  { time: "01:45:00", phaseA: 3.993, phaseB: 16.925, phaseC: 13.207 },
  { time: "02:20:00", phaseA: 11.876, phaseB: 7.293, phaseC: 19.628 },
  { time: "02:55:00", phaseA: 20.532, phaseB: 24.079, phaseC: 1.782 },
  { time: "03:30:00", phaseA: 23.418, phaseB: 14.576, phaseC: 5.342 },
  { time: "04:05:00", phaseA: 7.861, phaseB: 22.038, phaseC: 2.016 },
  { time: "04:40:00", phaseA: 1.903, phaseB: 4.781, phaseC: 17.642 },
  { time: "05:15:00", phaseA: 23.689, phaseB: 3.327, phaseC: 20.244 },
  { time: "05:50:00", phaseA: 9.126, phaseB: 10.155, phaseC: 15.284 },
  { time: "06:25:00", phaseA: 12.987, phaseB: 8.394, phaseC: 11.878 },
  { time: "05:15:00", phaseA: 18.791, phaseB: 6.315, phaseC: 19.226 },
  { time: "07:00:00", phaseA: 24.314, phaseB: 17.684, phaseC: 3.028 },
  { time: "07:35:00", phaseA: 9.782, phaseB: 2.897, phaseC: 14.823 },
  { time: "08:10:00", phaseA: 11.45, phaseB: 5.015, phaseC: 13.293 },
  { time: "08:45:00", phaseA: 24.672, phaseB: 19.352, phaseC: 5.092 },
  { time: "09:20:00", phaseA: 1.425, phaseB: 3.956, phaseC: 13.512 },
  { time: "09:55:00", phaseA: 4.998, phaseB: 18.971, phaseC: 20.482 },
  { time: "10:30:00", phaseA: 9.693, phaseB: 13.568, phaseC: 9.297 },
  { time: "11:05:00", phaseA: 21.236, phaseB: 14.288, phaseC: 10.87 },
  { time: "11:40:00", phaseA: 20.088, phaseB: 5.456, phaseC: 11.64 },
  { time: "12:15:00", phaseA: 1.732, phaseB: 20.814, phaseC: 22.732 },
  { time: "12:50:00", phaseA: 6.723, phaseB: 8.539, phaseC: 2.792 },
  { time: "13:25:00", phaseA: 19.736, phaseB: 13.236, phaseC: 1.272 },
  { time: "14:00:00", phaseA: 16.598, phaseB: 3.885, phaseC: 14.567 },
  { time: "14:35:00", phaseA: 9.383, phaseB: 11.757, phaseC: 5.94 },
  { time: "15:10:00", phaseA: 1.618, phaseB: 23.984, phaseC: 12.288 },
  { time: "15:45:00", phaseA: 5.979, phaseB: 10.125, phaseC: 22.413 },
  { time: "16:20:00", phaseA: 20.827, phaseB: 22.415, phaseC: 2.97 },
  { time: "16:55:00", phaseA: 12.458, phaseB: 3.193, phaseC: 20.222 },
  { time: "17:30:00", phaseA: 6.745, phaseB: 7.15, phaseC: 22.17 },
  { time: "18:05:00", phaseA: 22.072, phaseB: 11.743, phaseC: 4.618 },
  { time: "18:40:00", phaseA: 3.952, phaseB: 20.34, phaseC: 13.943 },
  { time: "19:15:00", phaseA: 14.876, phaseB: 3.638, phaseC: 23.684 },
  { time: "19:50:00", phaseA: 12.578, phaseB: 12.048, phaseC: 20.348 },
  { time: "20:25:00", phaseA: 8.486, phaseB: 11.39, phaseC: 10.622 },
  { time: "21:00:00", phaseA: 0.917, phaseB: 23.88, phaseC: 16.927 },
  { time: "21:35:00", phaseA: 24.802, phaseB: 13.125, phaseC: 0.71 },
  { time: "22:10:00", phaseA: 21.319, phaseB: 21.042, phaseC: 12.514 },
  { time: "22:45:00", phaseA: 4.22, phaseB: 18.051, phaseC: 14.492 },
  { time: "23:20:00", phaseA: 14.936, phaseB: 6.276, phaseC: 8.742 },
];

export const mpptPower = [
  {
    time: "00:00:00",
    mppt1: 0.193,
    mppt2: 0.278,
    mppt3: 0.125,
    mppt4: 0.407,
    mppt5: 0.091,
    mppt6: 0.319,
    mppt7: 0.255,
    mppt8: 0.146,
    mppt9: 0.389,
    mppt10: 0.235,
  },
  {
    time: "00:35:00",
    mppt1: 0.432,
    mppt2: 0.381,
    mppt3: 0.297,
    mppt4: 0.273,
    mppt5: 0.476,
    mppt6: 0.098,
    mppt7: 0.175,
    mppt8: 0.426,
    mppt9: 0.348,
    mppt10: 0.058,
  },
  {
    time: "01:10:00",
    mppt1: 0.444,
    mppt2: 0.201,
    mppt3: 0.112,
    mppt4: 0.482,
    mppt5: 0.327,
    mppt6: 0.183,
    mppt7: 0.346,
    mppt8: 0.245,
    mppt9: 0.074,
    mppt10: 0.403,
  },
  {
    time: "01:45:00",
    mppt1: 0.212,
    mppt2: 0.488,
    mppt3: 0.159,
    mppt4: 0.091,
    mppt5: 0.325,
    mppt6: 0.294,
    mppt7: 0.423,
    mppt8: 0.076,
    mppt9: 0.176,
    mppt10: 0.401,
  },
  {
    time: "02:20:00",
    mppt1: 0.214,
    mppt2: 0.143,
    mppt3: 0.385,
    mppt4: 0.285,
    mppt5: 0.212,
    mppt6: 0.416,
    mppt7: 0.437,
    mppt8: 0.352,
    mppt9: 0.463,
    mppt10: 0.412,
  },
  {
    time: "02:55:00",
    mppt1: 0.328,
    mppt2: 0.103,
    mppt3: 0.093,
    mppt4: 0.406,
    mppt5: 0.249,
    mppt6: 0.181,
    mppt7: 0.445,
    mppt8: 0.297,
    mppt9: 0.422,
    mppt10: 0.091,
  },
  {
    time: "03:30:00",
    mppt1: 0.183,
    mppt2: 0.434,
    mppt3: 0.315,
    mppt4: 0.238,
    mppt5: 0.105,
    mppt6: 0.425,
    mppt7: 0.233,
    mppt8: 0.415,
    mppt9: 0.361,
    mppt10: 0.369,
  },
  {
    time: "04:05:00",
    mppt1: 0.286,
    mppt2: 0.217,
    mppt3: 0.249,
    mppt4: 0.471,
    mppt5: 0.349,
    mppt6: 0.465,
    mppt7: 0.247,
    mppt8: 0.115,
    mppt9: 0.248,
    mppt10: 0.351,
  },
  {
    time: "04:40:00",
    mppt1: 0.057,
    mppt2: 0.442,
    mppt3: 0.274,
    mppt4: 0.098,
    mppt5: 0.392,
    mppt6: 0.467,
    mppt7: 0.106,
    mppt8: 0.135,
    mppt9: 0.089,
    mppt10: 0.303,
  },
  {
    time: "05:15:00",
    mppt1: 0.257,
    mppt2: 0.144,
    mppt3: 0.042,
    mppt4: 0.067,
    mppt5: 0.075,
    mppt6: 0.291,
    mppt7: 0.254,
    mppt8: 0.265,
    mppt9: 0.382,
    mppt10: 0.219,
  },
  {
    time: "05:50:00",
    mppt1: 0.063,
    mppt2: 0.174,
    mppt3: 0.391,
    mppt4: 0.119,
    mppt5: 0.327,
    mppt6: 0.241,
    mppt7: 0.336,
    mppt8: 0.138,
    mppt9: 0.159,
    mppt10: 0.432,
  },
  {
    time: "06:25:00",
    mppt1: 0.381,
    mppt2: 0.297,
    mppt3: 0.273,
    mppt4: 0.476,
    mppt5: 0.098,
    mppt6: 0.175,
    mppt7: 0.426,
    mppt8: 0.348,
    mppt9: 0.058,
    mppt10: 0.444,
  },
  {
    time: "07:00:00",
    mppt1: 0.201,
    mppt2: 0.112,
    mppt3: 0.482,
    mppt4: 0.327,
    mppt5: 0.183,
    mppt6: 0.346,
    mppt7: 0.245,
    mppt8: 0.074,
    mppt9: 0.403,
    mppt10: 0.212,
  },
  {
    time: "07:35:00",
    mppt1: 0.488,
    mppt2: 0.159,
    mppt3: 0.091,
    mppt4: 0.325,
    mppt5: 0.294,
    mppt6: 0.423,
    mppt7: 0.076,
    mppt8: 0.176,
    mppt9: 0.401,
    mppt10: 0.214,
  },
  {
    time: "08:10:00",
    mppt1: 0.143,
    mppt2: 0.385,
    mppt3: 0.285,
    mppt4: 0.212,
    mppt5: 0.416,
    mppt6: 0.437,
    mppt7: 0.352,
    mppt8: 0.463,
    mppt9: 0.412,
    mppt10: 0.328,
  },
  {
    time: "08:45:00",
    mppt1: 0.103,
    mppt2: 0.093,
    mppt3: 0.406,
    mppt4: 0.249,
    mppt5: 0.181,
    mppt6: 0.445,
    mppt7: 0.297,
    mppt8: 0.422,
    mppt9: 0.091,
    mppt10: 0.183,
  },
  {
    time: "09:20:00",
    mppt1: 0.434,
    mppt2: 0.315,
    mppt3: 0.238,
    mppt4: 0.105,
    mppt5: 0.425,
    mppt6: 0.233,
    mppt7: 0.415,
    mppt8: 0.361,
    mppt9: 0.369,
    mppt10: 0.286,
  },
  {
    time: "09:55:00",
    mppt1: 0.217,
    mppt2: 0.249,
    mppt3: 0.471,
    mppt4: 0.349,
    mppt5: 0.465,
    mppt6: 0.247,
    mppt7: 0.115,
    mppt8: 0.248,
    mppt9: 0.351,
    mppt10: 0.057,
  },
  {
    time: "10:30:00",
    mppt1: 0.442,
    mppt2: 0.274,
    mppt3: 0.098,
    mppt4: 0.392,
    mppt5: 0.467,
    mppt6: 0.106,
    mppt7: 0.135,
    mppt8: 0.089,
    mppt9: 0.303,
    mppt10: 0.257,
  },
  {
    time: "11:05:00",
    mppt1: 0.144,
    mppt2: 0.042,
    mppt3: 0.067,
    mppt4: 0.075,
    mppt5: 0.291,
    mppt6: 0.254,
    mppt7: 0.265,
    mppt8: 0.382,
    mppt9: 0.219,
    mppt10: 0.063,
  },
  {
    time: "11:40:00",
    mppt1: 0.174,
    mppt2: 0.391,
    mppt3: 0.119,
    mppt4: 0.327,
    mppt5: 0.241,
    mppt6: 0.336,
    mppt7: 0.138,
    mppt8: 0.159,
    mppt9: 0.432,
    mppt10: 0.381,
  },
  {
    time: "12:15:00",
    mppt1: 0.297,
    mppt2: 0.273,
    mppt3: 0.476,
    mppt4: 0.098,
    mppt5: 0.175,
    mppt6: 0.426,
    mppt7: 0.348,
    mppt8: 0.058,
    mppt9: 0.444,
    mppt10: 0.201,
  },
  {
    time: "12:50:00",
    mppt1: 0.112,
    mppt2: 0.482,
    mppt3: 0.327,
    mppt4: 0.183,
    mppt5: 0.346,
    mppt6: 0.245,
    mppt7: 0.074,
    mppt8: 0.403,
    mppt9: 0.212,
    mppt10: 0.488,
  },
  {
    time: "13:25:00",
    mppt1: 0.159,
    mppt2: 0.091,
    mppt3: 0.325,
    mppt4: 0.294,
    mppt5: 0.423,
    mppt6: 0.076,
    mppt7: 0.176,
    mppt8: 0.401,
    mppt9: 0.214,
    mppt10: 0.143,
  },
  {
    time: "14:00:00",
    mppt1: 0.385,
    mppt2: 0.285,
    mppt3: 0.212,
    mppt4: 0.416,
    mppt5: 0.437,
    mppt6: 0.352,
    mppt7: 0.463,
    mppt8: 0.412,
    mppt9: 0.328,
    mppt10: 0.103,
  },
  {
    time: "14:35:00",
    mppt1: 0.093,
    mppt2: 0.406,
    mppt3: 0.249,
    mppt4: 0.181,
    mppt5: 0.445,
    mppt6: 0.297,
    mppt7: 0.422,
    mppt8: 0.091,
    mppt9: 0.183,
    mppt10: 0.434,
  },
  {
    time: "15:10:00",
    mppt1: 0.315,
    mppt2: 0.238,
    mppt3: 0.105,
    mppt4: 0.425,
    mppt5: 0.233,
    mppt6: 0.415,
    mppt7: 0.361,
    mppt8: 0.369,
    mppt9: 0.286,
    mppt10: 0.217,
  },
  {
    time: "15:45:00",
    mppt1: 0.249,
    mppt2: 0.471,
    mppt3: 0.349,
    mppt4: 0.465,
    mppt5: 0.247,
    mppt6: 0.115,
    mppt7: 0.248,
    mppt8: 0.351,
    mppt9: 0.057,
    mppt10: 0.442,
  },
  {
    time: "16:20:00",
    mppt1: 0.274,
    mppt2: 0.098,
    mppt3: 0.392,
    mppt4: 0.467,
    mppt5: 0.106,
    mppt6: 0.135,
    mppt7: 0.089,
    mppt8: 0.303,
    mppt9: 0.257,
    mppt10: 0.144,
  },
  {
    time: "16:55:00",
    mppt1: 0.042,
    mppt2: 0.067,
    mppt3: 0.075,
    mppt4: 0.291,
    mppt5: 0.254,
    mppt6: 0.265,
    mppt7: 0.382,
    mppt8: 0.219,
    mppt9: 0.063,
    mppt10: 0.174,
  },
  {
    time: "17:30:00",
    mppt1: 0.391,
    mppt2: 0.119,
    mppt3: 0.327,
    mppt4: 0.241,
    mppt5: 0.336,
    mppt6: 0.138,
    mppt7: 0.159,
    mppt8: 0.432,
    mppt9: 0.381,
    mppt10: 0.297,
  },
  {
    time: "18:05:00",
    mppt1: 0.273,
    mppt2: 0.476,
    mppt3: 0.098,
    mppt4: 0.175,
    mppt5: 0.426,
    mppt6: 0.348,
    mppt7: 0.058,
    mppt8: 0.444,
    mppt9: 0.201,
    mppt10: 0.112,
  },
  {
    time: "18:40:00",
    mppt1: 0.482,
    mppt2: 0.327,
    mppt3: 0.183,
    mppt4: 0.346,
    mppt5: 0.245,
    mppt6: 0.074,
    mppt7: 0.403,
    mppt8: 0.212,
    mppt9: 0.488,
    mppt10: 0.159,
  },
  {
    time: "19:15:00",
    mppt1: 0.091,
    mppt2: 0.325,
    mppt3: 0.294,
    mppt4: 0.423,
    mppt5: 0.076,
    mppt6: 0.176,
    mppt7: 0.401,
    mppt8: 0.214,
    mppt9: 0.143,
    mppt10: 0.385,
  },
  {
    time: "19:50:00",
    mppt1: 0.285,
    mppt2: 0.212,
    mppt3: 0.416,
    mppt4: 0.437,
    mppt5: 0.352,
    mppt6: 0.463,
    mppt7: 0.412,
    mppt8: 0.328,
    mppt9: 0.103,
    mppt10: 0.093,
  },
  {
    time: "20:25:00",
    mppt1: 0.406,
    mppt2: 0.249,
    mppt3: 0.181,
    mppt4: 0.445,
    mppt5: 0.297,
    mppt6: 0.422,
    mppt7: 0.091,
    mppt8: 0.183,
    mppt9: 0.434,
    mppt10: 0.315,
  },
  {
    time: "21:00:00",
    mppt1: 0.238,
    mppt2: 0.105,
    mppt3: 0.425,
    mppt4: 0.233,
    mppt5: 0.415,
    mppt6: 0.361,
    mppt7: 0.369,
    mppt8: 0.286,
    mppt9: 0.217,
    mppt10: 0.249,
  },
  {
    time: "21:35:00",
    mppt1: 0.471,
    mppt2: 0.349,
    mppt3: 0.465,
    mppt4: 0.247,
    mppt5: 0.115,
    mppt6: 0.248,
    mppt7: 0.351,
    mppt8: 0.057,
    mppt9: 0.442,
    mppt10: 0.274,
  },
  {
    time: "22:10:00",
    mppt1: 0.098,
    mppt2: 0.392,
    mppt3: 0.467,
    mppt4: 0.106,
    mppt5: 0.135,
    mppt6: 0.089,
    mppt7: 0.303,
    mppt8: 0.257,
    mppt9: 0.144,
    mppt10: 0.042,
  },
  {
    time: "22:45:00",
    mppt1: 0.067,
    mppt2: 0.075,
    mppt3: 0.291,
    mppt4: 0.254,
    mppt5: 0.265,
    mppt6: 0.382,
    mppt7: 0.219,
    mppt8: 0.063,
    mppt9: 0.174,
    mppt10: 0.391,
  },
  {
    time: "23:20:00",
    mppt1: 0.119,
    mppt2: 0.327,
    mppt3: 0.241,
    mppt4: 0.336,
    mppt5: 0.138,
    mppt6: 0.159,
    mppt7: 0.432,
    mppt8: 0.381,
    mppt9: 0.297,
    mppt10: 0.273,
  },
];

export const equipColumns = [
  { id: "station_name", label: "Site", minWidth: 300 },
  { id: "device_name", label: "Device Name", minWidth: 100 },
  { id: "device_sn", label: "Device SN", minWidth: 100 },
  { id: "capacity", label: "Device Size (kW)", minWidth: 30 },
  { id: "device_type", label: "Device Type", minWidth: 30 },
  { id: "provider_name", label: "OEM", minWidth: 30 },
];

export const equipRows = [
  {
    site: "Ameen Farm House",
    device_name: "SUN200-100KTL-INMO",
    device_sn: "1190511_1_5_1",
    device_size: "100",
    oem: "Huawei",
  },
  {
    site: "TRTM FSD",
    device_name: "SUN200-100KTL-INMO",
    device_sn: "1190523_1_5_3",
    device_size: "100",
    oem: "Huawei",
  },
];

export const siteColumns = [
  { id: "station_name", label: "Site", minWidth: 300 },
  { id: "region", label: "Region", minWidth: 50 },
  { id: "category", label: "Categories", minWidth: 50 },
  { id: "total_system_size", label: "Total Sys Size (kWp)", minWidth: 80 },
  { id: "total_yield", label: "Total Yield (kWh)", minWidth: 80 },
  { id: "total_alarms", label: "Active Alarms", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 40 },
  { id: "export_enabled", label: "Export Enabled", minWidth: 50 },
  { id: "provider_name", label: "OEM", minWidth: 50 },
  // { id: "actions", label: "Actions", minWidth: 70 },
];

export const siteRows = [
  {
    site: "Ameen Farm House",
    region: "North",
    categories: "Gold",
    totalSysSize: "100",
    totalYield: "60,582",
    totalAlarms: "0",
    status: "Off",
  },
  {
    site: "Pepsi Phoolnagar",
    region: "South",
    categories: "Gold",
    totalSysSize: "100",
    totalYield: "60,582",
    totalAlarms: "0",
    status: "Off",
  },
];

export const userColumns = [
  { id: "id", label: "ID", minWidth: 50 },
  { id: "station_name", label: "Client Name", minWidth: 260 },
  { id: "username", label: "Username", minWidth: 110 },
  { id: "role", label: "Role", minWidth: 180 },
  { id: "created_at", label: "Created At", minWidth: 120 },
  { id: "actions", label: "Actions", minWidth: 70 },
];

export const alarmColumns = [
  { id: "id", label: "ID", minWidth: 120 },
  { id: "station_name", label: "Site", minWidth: 250 },
  { id: "event", label: "Events", minWidth: 120 },
  { id: "raised_time", label: "Alarm Start Date", minWidth: 200 },
  { id: "status", label: "Alarm Status", minWidth: 120 },
  { id: "elapsed_time", label: "Elapsed Time", minWidth: 120 },
  { id: "priority", label: "Priority", minWidth: 120 },
  { id: "actions", label: "Actions", minWidth: 120 },
];

export const ticketColumns = [
  { id: "id", label: "Alarm ID", width: 70 },
  { id: "ticketId", label: "Ticket ID", width: 90 },
  { id: "handlingQueue", label: "Handling Queue", width: 100 },
  { id: "assigneQueue", label: "Assignee Queue", width: 100 },
  { id: "prority", label: "Priority", width: 70 },
  { id: "status", label: "Status", width: 90 },
  { id: "rca", label: "RCA", width: 170 },
  { id: "actionTaken", label: "Action Taken", width: 130 },
  { id: "pendingReason", label: "Pending Reason", width: 110 },
  { id: "requestsName", label: "Request Name", width: 170 },
  { id: "taskName", label: "Task Name", width: 180 },
];

export const showTicketItems = {
  id: true,
  ticketId: true,
  handlingQueue: true,
  assigneQueue: true,
  prority: true,
  status: true,
  rca: true,
  actionTaken: true,
  pendingReason: true,
  requestsName: true,
  taskName: true,
};

export const inductionFormColumns = [
  { id: "id", label: "ID", minWidth: 120 },
  { id: "site_id", label: "Site Id", minWidth: 200 },
  { id: "site_name", label: "Site", minWidth: 250 },
  { id: "client_name", label: "Client Name", minWidth: 120 },
  { id: "status", label: "Application Status", minWidth: 120 },
  { id: "submitted_by", label: "Submitted By", minWidth: 120 },
  { id: "created_at", label: "Created At", minWidth: 120 },
  { id: "actions", label: "Actions", minWidth: 120 },
];

export const alarmRows = [
  {
    id: "01",
    site: "TRTM FSD",
    events: "PV abnormal alarm",
    alarmStartDate: "2023-05-09 11:17:51",
    alarmStatus: "Inactive",
    elapsedTime: "1 day",
    priority: "Critical",
  },
  {
    id: "02",
    site: "Pepsi Phoolnagar",
    events: "PV abnormal alarm",
    alarmStartDate: "2023-05-09 11:17:51",
    alarmStatus: "Inactive",
    elapsedTime: "1 day",
    priority: "Major",
  },
];

export const switchOptions = [
  {
    label: "Daily",
    value: "daily",
    selectedBackgroundColor: "#ffffff",
    selectedFontColor: "#000000",
  },
  {
    label: "Monthly",
    value: "monthly",
    selectedBackgroundColor: "#ffffff",
    selectedFontColor: "#000000",
  },
  {
    label: "Annual",
    value: "annual",
    selectedBackgroundColor: "#ffffff",
    selectedFontColor: "#000000",
  },
];

export const tabs = [
  {
    title: "Create New Template",
  },
  {
    title: "Use Saved Preference",
  },
];

export const sites = ["Pepsi Phoolnagar", "TRTM FSD", "Mughal Steel Mills"];

export const actualVsExpected = [
  { time: "00:00:00", actual: 3.79, expected: 0, curtailment: 0 },
  { time: "00:35:00", actual: 3.85, expected: 2.57, curtailment: 0 },
  { time: "01:10:00", actual: 4.24, expected: 3.67, curtailment: 0 },
  { time: "01:45:00", actual: 3.79, expected: 2.45, curtailment: 0 },
  { time: "02:20:00", actual: 3.56, expected: 5.34, curtailment: 0 },
  { time: "02:55:00", actual: 4.5, expected: 8.93, curtailment: 0 },
  { time: "03:30:00", actual: 4.67, expected: 8.93, curtailment: 0 },
  { time: "04:05:00", actual: 4.65, expected: 9.34, curtailment: 0 },
  { time: "04:40:00", actual: 4.68, expected: 9.43, curtailment: 0 },
  { time: "05:15:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "05:50:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "06:25:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "05:15:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "07:00:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "07:35:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "08:10:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "08:45:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "09:20:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "09:55:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "10:30:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "11:05:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "11:40:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "12:15:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "12:50:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "13:25:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "14:00:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "14:35:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "15:10:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "15:45:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "16:20:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "16:55:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "17:30:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "18:05:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "18:40:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "19:15:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "19:50:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "20:25:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "21:00:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "21:35:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "22:10:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "22:45:00", actual: 4.78, expected: 9.45, curtailment: 0 },
  { time: "23:20:00", actual: 4.78, expected: 9.45, curtailment: 0 },
];

export const perfRatioComp = [
  { site: "Ameen Farm House", perfRatio: 92.304, tempPerfRatio: 99.252 },
  { site: "TRTM FSD", perfRatio: 63.098, tempPerfRatio: 67.098 },
  { site: "Pepsi Co Greenhouse", perfRatio: 62.43, tempPerfRatio: 67.13 },
  { site: "Syngenta Warehouse", perfRatio: 53.87, tempPerfRatio: 57.32 },
  { site: "Ameen Farm House", perfRatio: 92.304, tempPerfRatio: 99.252 },
  { site: "TRTM FSD", perfRatio: 63.098, tempPerfRatio: 67.098 },
];

export const regions = [
  { name: "North", label: "North", id: 1 },
  { name: "South", label: "South", id: 2 },
  { name: "Central", label: "Central", id: 3 },
];

export const status = [
  { label: "Active", id: 1 },
  { label: "Inactive", id: 2 },
];

export const time = [
  { label: "Ascending", id: 1 },
  { label: "Descending", id: 2 },
];

export const category = [
  { label: "Platinum", id: 1 },
  { label: "Gold", id: 2 },
  { label: "Silver", id: 3 },
];

export const severity = [
  { label: "Critical", id: 1 },
  { label: "Major", id: 2 },
  { label: "Minor", id: 3 },
];

export const networkText = "Check Internet Connectivity!";

export const strokeColor = [
  "#228b22",
  "#e1ad01",
  "#cddc39",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#5cb85c",
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#8bc34a",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
];

//Induction Scr Data
export const steps = [
  "Site Details",
  "System Details",
  "General Details",
  "Inverter Details",
];

export const siteType1 = [
  { value: "Commercial", label: "Commercial" },
  { value: "Residential", label: "Residential" },
  { value: "Industrial", label: "Industrial" },
];

export const exportType = [
  { value: "Export-Enable", label: "Export-Enable" },
  { value: "Export-Disable", label: "Export-Disable" },
];

export const siteCategory = [
  { value: "Gold", label: "Gold" },
  { value: "Silver", label: "Silver" },
  { value: "Platinum", label: "Platinum" },
];

export const siteType2 = [
  { value: "Grid-Tied", label: "Grid-Tied" },
  { value: "Hybrid", label: "Hybrid" },
];
export const mountType = [
  { value: "Ground", label: "Ground" },
  { value: "Roof", label: "Roof" },
];

export const panelKind = [
  { value: "Mono", label: "Mono" },
  { value: "Bi-Facial", label: "Bi-Facial" },
];

export const mpptColumns = [
  { id: "mppt", label: "MPPT", minWidth: 120 },
  { id: "input", label: "Input", minWidth: 120 },
  { id: "noOfPanels", label: "No of Panels", minWidth: 120 },
  { id: "mountType", label: "Mount Type (Grid/Roof)", minWidth: 120 },

  { id: "orientation", label: "Orientation", minWidth: 120 },
  { id: "tilt", label: "Tilt", minWidth: 120 },
  { id: "voc", label: "Voc", minWidth: 120 },
  { id: "vmp", label: "Vmp", minWidth: 120 },
  { id: "imp", label: "Imp", minWidth: 120 },
  { id: "actions", label: "Actions", minWidth: 120 },
];

export const ViewInductionColumns = [
  { id: "mppt", label: "MPPT", minWidth: 120 },
  { id: "input", label: "Input", minWidth: 120 },
  { id: "noOfPanels", label: "No of Panels", minWidth: 120 },
  { id: "mountType", label: "Mount Type (Grid/Roof)", minWidth: 120 },
  { id: "orientation", label: "Orientation", minWidth: 120 },
  { id: "tilt", label: "Tilt", minWidth: 120 },
  { id: "voc", label: "Voc", minWidth: 120 },
  { id: "vmp", label: "Vmp", minWidth: 120 },
  { id: "imp", label: "Imp", minWidth: 120 },
];
export const ViewInductionExpectedColumns = [
  { id: "january", label: "Jan" },
  { id: "february", label: "Feb" },
  { id: "march", label: "Mar" },
  { id: "april", label: "Apr" },
  { id: "may", label: "May" },
  { id: "june", label: "Jun" },
  { id: "july", label: "Jul" },
  { id: "august", label: "Aug" },
  { id: "september", label: "Sep" },
  { id: "october", label: "Oct" },
  { id: "november", label: "Nov" },
  { id: "december", label: "Dec" },
];
export const regionList = [
  { value: "North", label: "North" },
  { value: "South", label: "South" },
  { value: "Central", label: "Central" },
];

export const citiesList = [
  "Islamabad",
  "Ahmed Nager",
  "Ahmadpur East",
  "Ali Khan",
  "Alipur",
  "Arifwala",
  "Attock",
  "Bhera",
  "Bhalwal",
  "Bahawalnagar",
  "Bahawalpur",
  "Bhakkar",
  "Burewala",
  "Chillianwala",
  "Chakwal",
  "Chichawatni",
  "Chiniot",
  "Chishtian",
  "Daska",
  "Darya Khan",
  "Dera Ghazi",
  "Dhaular",
  "Dina",
  "Dinga",
  "Dipalpur",
  "Faisalabad",
  "Fateh Jhang",
  "Ghakhar Mandi",
  "Gojra",
  "Gujranwala",
  "Gujrat",
  "Gujar Khan",
  "Hafizabad",
  "Haroonabad",
  "Hasilpur",
  "Haveli",
  "Lakha",
  "Jalalpur",
  "Jattan",
  "Jampur",
  "Jaranwala",
  "Jhang",
  "Jhelum",
  "Kalabagh",
  "Karor Lal",
  "Kasur",
  "Kamalia",
  "Kamoke",
  "Khanewal",
  "Khanpur",
  "Kharian",
  "Khushab",
  "Kot Adu",
  "Jauharabad",
  "Lahore",
  "Lalamusa",
  "Layyah",
  "Liaquat Pur",
  "Lodhran",
  "Malakwal",
  "Mamoori",
  "Mailsi",
  "Mandi Bahauddin",
  "mian Channu",
  "Mianwali",
  "Multan",
  "Murree",
  "Muridke",
  "Mianwali Bangla",
  "Muzaffargarh",
  "Narowal",
  "Okara",
  "Renala Khurd",
  "Pakpattan",
  "Pattoki",
  "Pir Mahal",
  "Qaimpur",
  "Qila Didar",
  "Rabwah",
  "Raiwind",
  "Rajanpur",
  "Rahim Yar",
  "Rawalpindi",
  "Sadiqabad",
  "Safdarabad",
  "Sahiwal",
  "Sangla Hill",
  "Sarai Alamgir",
  "Sargodha",
  "Shakargarh",
  "Sheikhupura",
  "Sialkot",
  "Sohawa",
  "Soianwala",
  "Siranwali",
  "Talagang",
  "Taxila",
  "Toba Tek",
  "Vehari",
  "Wah Cantonment",
  "Wazirabad",
  "Badin",
  "Bhirkan",
  "Rajo Khanani",
  "Chak",
  "Dadu",
  "Digri",
  "Diplo",
  "Dokri",
  "Ghotki",
  "Haala",
  "Hyderabad",
  "Islamkot",
  "Jacobabad",
  "Jamshoro",
  "Jungshahi",
  "Kandhkot",
  "Kandiaro",
  "Karachi",
  "Kashmore",
  "Keti Bandar",
  "Khairpur",
  "Kotri",
  "Larkana",
  "Matiari",
  "Mehar",
  "Mirpur Khas",
  "Mithani",
  "Mithi",
  "Mehrabpur",
  "Moro",
  "Nagarparkar",
  "Naudero",
  "Naushahro Feroze",
  "Naushara",
  "Nawabshah",
  "Nazimabad",
  "Qambar",
  "Qasimabad",
  "Ranipur",
  "Ratodero",
  "Rohri",
  "Sakrand",
  "Sanghar",
  "Shahbandar",
  "Shahdadkot",
  "Shahdadpur",
  "Shahpur Chakar",
  "Shikarpaur",
  "Sukkur",
  "Tangwani",
  "Tando Adam",
  "Tando Allahyar",
  "Tando Muhammad",
  "Thatta",
  "Umerkot",
  "Warah",
  "Abbottabad",
  "Adezai",
  "Alpuri",
  "Akora Khattak",
  "Ayubia",
  "Banda Daud",
  "Bannu",
  "Batkhela",
  "Battagram",
  "Birote",
  "Chakdara",
  "Charsadda",
  "Chitral",
  "Daggar",
  "Dargai",
  "Darya Khan",
  "dera Ismail",
  "Doaba",
  "Dir",
  "Drosh",
  "Hangu",
  "Haripur",
  "Karak",
  "Kohat",
  "Kulachi",
  "Lakki Marwat",
  "Latamber",
  "Madyan",
  "Mansehra",
  "Mardan",
  "Mastuj",
  "Mingora",
  "Nowshera",
  "Paharpur",
  "Pabbi",
  "Peshawar",
  "Saidu Sharif",
  "Shorkot",
  "Shewa Adda",
  "Swabi",
  "Swat",
  "Tangi",
  "Tank",
  "Thall",
  "Timergara",
  "Tordher",
  "Awaran",
  "Barkhan",
  "Chagai",
  "Dera Bugti",
  "Gwadar",
  "Harnai",
  "Jafarabad",
  "Jhal Magsi",
  "Kacchi",
  "Kalat",
  "Kech",
  "Kharan",
  "Khuzdar",
  "Killa Abdullah",
  "Killa Saifullah",
  "Kohlu",
  "Lasbela",
  "Lehri",
  "Loralai",
  "Mastung",
  "Musakhel",
  "Nasirabad",
  "Nushki",
  "Panjgur",
  "Pishin valley",
  "Quetta",
  "Sherani",
  "Sibi",
  "Sohbatpur",
  "Washuk",
  "Zhob",
  "Ziarat",
];

export const stations_data = [
  {
    x: 250,
    y: 290,
    icon: Home,
  },
  {
    x: 110,
    y: 290,
    icon: Grid,
  },
  {
    x: 250,
    y: 450,
    icon: Generator,
  },
  {
    x: 400,
    y: 290,
    icon: Battery,
  },
  {
    x: 250,
    y: 125,
    icon: SolarPanel,
  },
];

export const meterHierarchy_data = [
  {
    x: 300,
    y: 225,
    icon: Home,
  },
  {
    x: 120,
    y: 225,
    icon: Grid,
  },
  {
    x: 300,
    y: 400,
    icon: Generator,
  },
  {
    x: 485,
    y: 225,
    icon: Battery,
  },
  {
    x: 300,
    y: 50,
    icon: SolarPanel,
  },
];

export const alarmStatusList = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const priorityList = [
  { value: "Minor", label: "Minor" },
  { value: "Major", label: "Major" },
  { value: "Critical", label: "Critical" },
];

export const roleList = [
  { value: "Admin", label: "Admin" },
  { value: "Installer", label: "Installer" },
  { value: "Client", label: "Client" },
  { value: "OPS Manager", label: "OPS Manager" },
];

export const providerList = [
  { value: "Huawei", label: "Huawei" },
  { value: "Solis", label: "Solis" },
  { value: "Sungrow", label: "Sungrow" },
  { value: "Solarman", label: "Solarman" },
];

export const rowstick = [
  {
    id: 1234,
    ticketId: "15200548",
    handlingQueue: "ES_NOC",
    assigneQueue: "Asad Munir",
    prority: "Low",
    status: "Resolved",
    rca: "Inverter Over Temperature",
    actionTaken: "Inventor reset",
    pendingReason: null,
    requestsName: "Energy Solutions Related Complaints",
    taskName: "Inverter Related Faults",
  },
  {
    id: 1,
    ticketId: "15200548",
    handlingQueue: "ES_Projects",
    assigneQueue: "Asad Munir",
    prority: "Low",
    status: "Open",
    rca: "Inverter Over Temperature",
    actionTaken: "Inventor reset",
    pendingReason: null,
    requestsName: "Energy Solutions Related Complaints",
    taskName: "Inverter Related Faults",
  },
  {
    id: 2,
    ticketId: "15200548",
    handlingQueue: "ES_NOC",
    assigneQueue: "Asadww Munir",
    prority: "Low",
    status: "Resolved",
    rca: "Inverter Over Temperature",
    actionTaken: "Inventor reset",
    pendingReason: null,
    requestsName: "Energy Solutions Related Complaints",
    taskName: "Inverter Related Faults",
  },
  {
    id: 3,
    ticketId: "15200548",
    handlingQueue: "ES_Infra",
    assigneQueue: "Asad MunirInfra",
    prority: "Low",
    status: "Pending",
    rca: "Inverter Over Temperature",
    actionTaken: "Inventor reset",
    pendingReason: null,
    requestsName: "Energy Solutions Related Complaints",
    taskName: "Inverter Related Faults",
  },
];

export const roles = {
  ADMIN: "Admin",
  SUPER_ADMIN: "Super Admin",
  SITE_MANAGER: "Site Manager",
  CLIENT: "Client",
  OPS_MANAGER: "OPS Manager",
  INSTALLER: "Installer",
};
export const passwordMessage =
  "Password must be at least 8 characters with uppercase, lowercase, number, and special character.";

export const filterOptions = ["day", "month", "year"];
export const oemOptions = ["All OEM", "Huawei", "Sungrow", "Solis", "Solarman"];

export const displayDateFormats = {
  date: "DD-MMM-YYYY",
  month: "MMM-YY",
  year: "YYYY",
};
