import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Box } from "@mui/material";
import {
  AppContainer,
  AppHeading,
  ChartCard,
  EquipDetCard,
  LineCharts,
} from "../../../components";
import { getMeterDetail } from "../../../redux/actions";
import { imbalanceTabs, networkText } from "../../../utilities";

const EquipmentDetails = ({ navigation }) => {
  const dispatch = useDispatch(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [imbalanceTab, setImbalanceTab] = useState("Phase Voltage Imbalance");
  const id = location?.state?.data?.id || null;
  const deviceName = location?.state?.data?.device_name || "Device Details";
  const { meterDetail, meterDetLoad } = useSelector((state) => state?.devices);

  const progCirData = meterDetail?.grid_connected_pencentage;

  useEffect(() => {
    getData(id);
  }, []);

  const getData = async (value) => {
    if (navigator.onLine) {
      const requestBody = {
        device_id: value,
      };
      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
          }
        },
        onFailure: (error) => {},
      };

      dispatch(getMeterDetail(body));
    } else {
      toast.error(networkText);
    }
  };

  return (
    <AppContainer loading={meterDetLoad}>
      <Box sx={{ p: 3, width: "100%" }}>
        <Col className="equipDetCon">
          <AppHeading title={deviceName} />
          <EquipDetCard
            monthlyConsmp={meterDetail?.monthly_consymption}
            todayConsmp={meterDetail?.today_consumption}
            netMetering={meterDetail?.net_metering}
            mdi={null}
            powerFactor={meterDetail?.power_factor}
            gridFreq={meterDetail?.grid_frequency}
            avgActPower={meterDetail?.avg_active_power}
            avgReactPower={meterDetail?.avg_reactive_power}
            stations={meterDetail?.device_hierarchy_data}
            links={meterDetail?.device_hierarchy_links}
            gridDownTime={meterDetail?.grid_downtime}
            thd={meterDetail?.thd}
            voltA={meterDetail?.voltage_A}
            voltB={meterDetail?.voltage_B}
            voltC={meterDetail?.voltage_C}
            currA={meterDetail?.current_A}
            currB={meterDetail?.current_B}
            currC={meterDetail?.current_C}
          />

          <Row gutter={[20, 50]}>
            {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <ChartCard title={"Daily Loadshedding"} mT={50}>
                {progCirData !== null && (
                  <ProgressChart
                    data={progCirData}
                    height={"310px"}
                    label={"Grid Connected"}
                  />
                )}
              </ChartCard>
            </Col> */}
            {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <ChartCard title={"Energy Logger"} mT={50}>
                <ProgressLineChart
                  totalValue={meterDetail?.net_energy}
                  coveredValue={meterDetail?.energy_off_peak}
                  strokeColor={"var(--color-wateen)"}
                  trailColor={"var(--g3)"}
                  title={"Energy Off Peak"}
                  mT={15}
                />
                <ProgressLineChart
                  totalValue={meterDetail?.net_energy}
                  coveredValue={meterDetail?.energy_peak}
                  strokeColor={"var(--color-wateen)"}
                  trailColor={"var(--g3)"}
                  title={"Energy Peak"}
                  mT={15}
                />
                <ProgressLineChart
                  totalValue={meterDetail?.net_energy}
                  coveredValue={meterDetail?.reactive_energy_off_peak}
                  strokeColor={"var(--color-wateen)"}
                  trailColor={"var(--g3)"}
                  title={"Reactive Energy Off Peak"}
                  mT={15}
                />
                <ProgressLineChart
                  totalValue={meterDetail?.net_energy}
                  coveredValue={meterDetail?.reactive_energy_peak}
                  strokeColor={"var(--color-wateen)"}
                  trailColor={"var(--g3)"}
                  title={"Reactive Energy Peak"}
                  mT={15}
                />
              </ChartCard>
            </Col> */}
          </Row>

          <ChartCard
            title={"Imbalance (%)"}
            mT={50}
            tabs={imbalanceTabs}
            activeTab={imbalanceTab}
            setActiveTab={(item) => setImbalanceTab(item)}
          >
            <LineCharts
              data={meterDetail?.meter_data}
              nameKey={"date"}
              dataKey={["imbalance"]}
              showGridVertical={true}
              showGridHorizontal={true}
              xAxisLabel={"Time"}
              yAxisLabel={"(%)"}
            />
          </ChartCard>
          <ChartCard title={"Phase wise Active Power"} mT={50}>
            <LineCharts
              data={meterDetail?.meter_data}
              nameKey={"date"}
              dataKey={["phase_a", "phase_b", "phase_c"]}
              showGridVertical={true}
              showGridHorizontal={true}
              yAxisLabel={"kW"}
            />
          </ChartCard>
        </Col>
      </Box>
    </AppContainer>
  );
};

export default EquipmentDetails;
